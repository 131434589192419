import styled from "styled-components";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../../styles/constants";
interface ProfileCardProps {
  isactive: boolean;
}
export const ProfileCard = styled.div<ProfileCardProps>`
  height: 4rem;
  width: 100%;
  padding: 1rem;
  border-left: ${(props) => (props.isactive ? "4px solid #1da57a" : "none")};

  @media screen and (max-width: 900px) {
    border: ${(props) => (props.isactive ? "4px solid #1da57a" : "none")};
    border-radius: 7px;
    width: 5rem;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: auto;
    &:hover {
      border: 4px solid #1da57a;
    }
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.1s ease-out;

  &:hover {
    border-left: 4px solid #1da57a;
  }
`;

export const StyledImage = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
  }
`;

export const Details = styled.div`
  width: 30%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Name = styled(BaseTypography)`
  @media screen and (max-width: 900px) {
    font-size: ${FONT_SIZE.xxxs};

    text-align: center;
  }
`;

export const LastMessage = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xs};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NumberCount = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #1da57a;
  text-align: center;
`;
