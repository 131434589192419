import React, { useEffect, useState } from "react";
import * as S from "./PopularProducts.styles";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../common/PageTitle/PageTitle";
import { BasicTable } from "./BasicTable/BasicTable";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { BaseImage } from "../../../common/BaseImage/BaseImage";
import download from "../../../../assets/images/download.png";
import { Excel } from "antd-table-saveas-excel";
import { DatePicker } from "antd";
import moment from "moment";
import { BASE_COLORS } from "../../../../styles/constants";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { LeftCircleFilled } from "@ant-design/icons";
import { MdDownloadForOffline } from "react-icons/md";

const columns: any = [
  {
    title: "Classement",
    dataIndex: "classement",
    width: "15%",
    editable: true,
  },
  {
    title: "Produit",
    dataIndex: "name",
    width: "25%",
    editable: true,
  },
  {
    title: "Quantité commandée",
    dataIndex: "quantity",
    width: "20%",
    editable: true,
  },
];

export const PopularProductsDetailed: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  // const selectedDate = useSelector(
  //   (state: RootState) => state.reporting.selectedDay
  // );

  // const produitsPopualires =
  //   useSelector(
  //     (state: RootState) =>
  //       state.reporting?.reportingByDay?.produitsPopualires
  //   );

  useEffect(() => {
    // dispatch(getReportByDay(selectedDate));
    setLoading(false);
  }, []);

  const handleClick = () => {
    navigate("/reporting");
  };

  const handleDownloadClick = () => {
    const excel = new Excel();
    // const dataSource = produitsPopualires?.map((item: any, index: number) => ({
    //   classement: index + 1,
    //   name: item.name,
    //   quantity: item.somme,
    // }));
    const popularProducts: any = [
      {
        name: "potato",
        somme: 234,
      },
      {
        name: "tomato",
        somme: 234,
      },
      {
        name: "orange",
        somme: 234,
      },
    ];
    const dataSource = popularProducts?.map((item: any, index: number) => ({
      classement: index + 1,
      name: item.name,
      quantity: item.somme,
    }));
    excel
      .addSheet("Produits populaires")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true,
      })
      .saveAs("Produits populaires.xlsx");
  };

  return (
    <>
      <PageTitle>{"Produits populaires"}</PageTitle>
      <BaseRow
        justify={"space-between"}
        style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "1rem" }}
      >
        <BaseRow>
          <LeftCircleFilled
            onClick={handleClick}
            style={{
              marginRight: "1rem",
              cursor: "pointer",
              color: "#5daf4b",
              fontSize: "40px",
            }}
          />
          <DatePicker
            style={{ height: "3rem" }}
            // value={selectedDate ? moment(selectedDate) : null}
            // onChange={(date: any) => {
            //   if (date) {
            //     dispatch(setDay(moment(date).format("YYYY/MM/DD")));
            //     console.log(date);
            //   }
            // }}
          />
        </BaseRow>
        <MdDownloadForOffline
          onClick={handleDownloadClick}
          style={{
            marginRight: "1rem",
            cursor: "pointer",
            color: "#5daf4b",
            fontSize: "50px",
          }}
        />
      </BaseRow>

      <S.TablesWrapper>
        <BasicTable loading={loading} columns={columns} />
      </S.TablesWrapper>
    </>
  );
};

export default PopularProductsDetailed;
