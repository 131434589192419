import {
  Button,
  Form,
  FormProps,
  Image,
  Input,
  Radio,
  Upload,
  UploadProps,
  message,
} from "antd";
import { BaseCard } from "../../common/BaseCard/BaseCard";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import * as S from "./PacksSettings.styles";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { useState } from "react";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { UploadOutlined } from "@ant-design/icons";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { EditableTable } from "./editableTable/EditableTable";
import { PackageData } from "../../../api/packages.api";
import { useDispatch, useSelector } from "react-redux";
import { addPackage } from "../../../store/slices/packagesSlice";
import { RootState } from "../../../store/store";
type FieldType = {
  name?: string;
  percentage?: number;
};

export const PacksSettings: React.FC = () => {
  const [form] = BaseForm.useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.packages);

  const isLoading: boolean =
    loading !== "idle" && loading !== "succeeded" && loading !== "failed";
  const packageColumns = [
    {
      title: "Nom du paquet",
      dataIndex: "name",
      width: "40%",
      editable: true,
      render: (text: string, record: any) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Pourcentage (%)",
      dataIndex: "percentage",
      width: "40%",
      editable: true,
      render: (text: string, record: any) => {
        return <span>{text}</span>;
      },
    },
  ];

  const radioOptions = [
    { value: true, label: "oui" },
    { value: false, label: "non" },
  ];

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      const values: PackageData = await form.validateFields();
      console.log(values);

      dispatch(addPackage(values))
        .then(() => {
          form.resetFields();
        })
        .catch((error: any) => {
          console.error("Error adding ingredient:", error);
        });
    } catch (error) {
      console.error("Error validating fields:", error);
    }
  };

  return (
    <S.CardContent>
      <S.Title>Paramètres des paquets</S.Title>
      <S.Description>
        Mettez à jour votre packets de promotion si vous en utilisez
      </S.Description>

      <BaseRow style={{ width: "100%" }} gutter={16} justify={"space-between"}>
        <BaseCol xs={24} md={16}>
          <BaseRow
            justify={"start"}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <BaseTypography style={{ marginRight: "1rem" }}>
              Activer l'option des paquets
            </BaseTypography>
            <Radio.Group options={radioOptions}></Radio.Group>
          </BaseRow>
          <S.TablesWrapper>
            <EditableTable columns={packageColumns} />
          </S.TablesWrapper>
        </BaseCol>
        <BaseCol xs={24} md={8}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <BaseRow style={{ width: "100%", padding: "1rem" }} gutter={16}>
              <BaseCol span={24}>
                <Form.Item
                  label="Nom du paquet"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir le nom du paquet",
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </BaseCol>
              <BaseCol span={24}>
                <Form.Item
                  label="Pourcentage"
                  name="percentage"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir le pourcentage",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <BaseRow
                  style={{ width: "100%", padding: "1rem" }}
                  justify={"end"}
                >
                  <BaseButton
                    loading={isLoading}
                    size="small"
                    type="primary"
                    htmlType="submit"
                  >
                    Ajouter
                  </BaseButton>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </Form>
        </BaseCol>
      </BaseRow>
    </S.CardContent>
  );
};
