import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import loginBackground from "../../../assets/images/auth/login_bg.png";
import {
  BORDER_RADIUS,
  media,
  FONT_SIZE,
  FONT_WEIGHT,
  BASE_COLORS,
} from "../../../styles/constants";
import { BaseInput as CommonInput } from "../../common/inputs/BaseInput/BaseInput";
import { InputPassword as CommonInputPassword } from "../../common/inputs/InputPassword/InputPassword";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BaseCheckbox } from "../../common/BaseCheckbox/BaseCheckbox";

export const Wrapper = styled.div`
  height: 90vh;
  display: flex;
`;

export const FormWrapper = styled.div`
  padding: 2.5rem;
  width: 100%;
  overflow: auto;
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  z-index: 10;
  @media only screen and ${media.xs} {
    padding: 2.5rem 1.25rem;
    width: 100%;
    max-height: calc(100vh - 3rem);
  }

  @media only screen and ${media.md} {
    padding: 2.5rem;
    width: 100%;
    max-height: calc(100vh - 3rem);
  }
`;

export const FormTitle = styled.div`
  color: var(--text-main-color);

  margin-bottom: 0.9375rem;
  font-size: 2rem;
  font-weight: ${FONT_WEIGHT.bold};
  line-height: 2.125rem;
`;

export const FormCheckbox = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1);
  }

  & .ant-checkbox-input {
    transform: scale(1);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.5rem;
  color: var(--text-main-color);
  & .ant-form-item-control-input {
    min-height: 1.125rem;
  }

  & .ant-form-item-label > label {
    color: var(--text-main-color);
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    font-size: ${FONT_SIZE.md};
    line-height: 1.25rem;
    color: var(--text-main-color);
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  height: 2.2rem;
  background-color: var(--input-background-color);
  border-radius: 7px;
  border: 1px solid var(--text-main-color);
  & input.ant-input {
    background-color: var(--input-background-color);
  }
`;

export const FormInputPassword = styled(CommonInputPassword)`
  color: var(--text-main-color);
  background-color: var(--input-background-color);
  height: 2.2rem;
  border-radius: 7px;
  border: 1px solid var(--text-main-color);
  & input.ant-input {
    background-color: var(--input-background-color);
  }

  & .ant-input-suffix span svg {
    fill: var(--text-main-color);
    position: absolute;
    right: 10px;
  }

  & > input {
    height: 32px;
    padding-left: 10px !important;
    top: 0px;
    position: absolute;
    left: 0px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 1.3rem;
`;

export const Text = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`;

export const SubmitButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--background-color);
  width: 100%;
  height: 2.2rem;
  background-color: var(--secondary-background-color);
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: #5cae4a;
  }
`;

export const SignUpButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--background-color);
  width: 100%;
  height: 2.2rem;
  border: none;
  background-color: var(--secondary-background-color);

  &:hover,
  &:active,
  &:focus {
    background-color: #5cae4a;
  }
`;

export const SocialButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`;
