import styled from "styled-components";
import { BaseRow } from "../../common/BaseRow/BaseRow";

export const StyledRow = styled(BaseRow)`
  & :first-child {
    & .ant-card {
      background-color: #c9caef;

      & .ant-card-body div .ant-typography {
        color: #111315;
      }

      & .ant-card-body {
        & div:first-child {
          background-color: #f8f8f8;
        }
      }
    }
  }
  margin-bottom: 5px;
`;
