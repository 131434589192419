import styled from "styled-components";
import {
  BASE_COLORS,
  FONT_SIZE,
  BORDER_RADIUS,
} from "../../../../../styles/constants";
import { BaseMenu } from "../../../../common/BaseMenu/BaseMenu";

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.md};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role="img"],
  a,
  .ant-menu-item {
    color: var(--text-sider-secondary-color);
    fill: var(--text-sider-secondary-color);
    border-radius: ${BORDER_RADIUS};
  }

  .ant-menu-item-selected {
    background-color: var(--active-sider-link-bg) !important;
    color: var(--text-sider-active-color) !important;
  }

  .ant-menu-item,
  .ant-menu-item-selected,
  .ant-menu-item-active {
    color: var(--text-sider-active-color) !important;
  }
`;
