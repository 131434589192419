import { useDispatch, useSelector } from "react-redux";
import { FONT_SIZE } from "../../../styles/constants";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "../Orders.styles";
import { OrderCard } from "../components/OrderCard/OrderCard";
import { useEffect } from "react";
import { getPendingOrders } from "../../../store/slices/ordersSlice";
import { RootState } from "../../../store/store";
import { Order } from "../../../domain/ordersModel";
import { Skeleton } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const PendingOrders = () => {
  const dispatch = useDispatch();
  const {
    pendingOrders: { demandes },
    loading,
  } = useSelector((state: RootState) => state.orders);

  const isLoading: boolean =
    loading !== "idle" && loading !== "succeeded" && loading !== "failed";

  useEffect(() => {
    dispatch(getPendingOrders());
  }, []);

  return (
    <S.Main>
      <BaseRow>
        <BaseTypography
          style={{ fontSize: FONT_SIZE.xxxxl, marginLeft: "1rem" }}
        >
          Commandes en attentes
        </BaseTypography>
      </BaseRow>
      <S.CardsWrapper>
        <BaseRow style={{ gap: "1rem" }} gutter={[0, 24]}>
          {isLoading && (
            <>
              <Skeleton
                style={{ height: "16rem", width: "15rem" }}
                avatar
                active
                loading={true}
              />
            </>
          )}
          {!isLoading && demandes.length === 0 ? (
            <>
              <S.NoDataFound>
                <ExclamationCircleFilled style={{ marginRight: "5px" }} />
                Aucune commande pour l'instant
              </S.NoDataFound>
            </>
          ) : (
            !isLoading &&
            demandes?.map((order: Order) => (
              <OrderCard
                name={order.name}
                tele={order.tele}
                statut={order.statut}
                adresse={order.adresse}
                ville={order.ville}
                expire={order.expire}
                id={order.id}
                numCommande={order.numCommande}
                somme={order.somme}
                key={order.id}
                date_estime={order.date_estime}
                date_livre={order.date_livre}
              />
            ))
          )}
        </BaseRow>
      </S.CardsWrapper>
    </S.Main>
  );
};
