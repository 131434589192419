import { useTranslation } from "react-i18next";
import { BaseRow } from "../common/BaseRow/BaseRow";
import { BaseCol } from "../common/BaseCol/BaseCol";
import { DatePicker } from "antd";
import { BaseTypography } from "../common/BaseTypography/BaseTypography";
import * as S from "./Reporting.styles";
import { DownOutlined } from "@ant-design/icons";
import { FONT_SIZE } from "../../styles/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import GeneralReporting from "./GeneralReporting/GeneralReporting";

export const Reporting = () => {
  const { t } = useTranslation();
  const theme = useSelector((state: RootState) => state.theme.theme);

  const datePickerIcon = <DownOutlined />;

  return (
    <S.Main>
      <S.FlexRow>
        <BaseTypography style={{ fontSize: FONT_SIZE.xxxxl }}>
          Rapports détaillés
        </BaseTypography>
        <DatePicker
          style={{
            height: "2rem",
            border: "none",
            backgroundColor: "var(--card-background-color)",
            marginRight: "1rem",
          }}
          suffixIcon={datePickerIcon}
        ></DatePicker>
      </S.FlexRow>
      <GeneralReporting />
    </S.Main>
  );
};
