import { FONT_SIZE } from "../../../styles/constants";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "./ProductItem.styles";

interface ProductItemProps {
  position: number;
  ingredientName: string;
  deliveryTotale: number;
}

export const ProductItem: React.FC<ProductItemProps> = ({
  position,
  ingredientName,
  deliveryTotale,
}) => (
  <>
    <S.ProductItem>
      <S.PositionNumber>
        <BaseTypography style={{ fontSize: FONT_SIZE.xl }}>
          {position}
        </BaseTypography>
      </S.PositionNumber>
      <S.ProductInfo>
        <BaseTypography style={{ fontSize: FONT_SIZE.xl }}>
          {ingredientName}
        </BaseTypography>
        <S.DeliveryInfo>
          <BaseTypography
            style={{
              fontSize: FONT_SIZE.xs,
              marginRight: "10px",
              color: "var(--text-sider-secondary-color)",
            }}
          >
            commandes :
          </BaseTypography>
          <BaseTypography style={{ fontSize: FONT_SIZE.xs }}>
            {deliveryTotale}
          </BaseTypography>
        </S.DeliveryInfo>
      </S.ProductInfo>
    </S.ProductItem>
  </>
);
