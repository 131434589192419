import { useDispatch, useSelector } from "react-redux";
import * as S from "./RestaurantCard.styles";
import { RootState } from "../../../../store/store";
import { useTranslation } from "react-i18next";
import { DeleteFilled } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import {
  RestaurantData,
  RestaurantPackageData,
} from "../../../../api/restaurants.api";
import {
  acceptInvite,
  deleteConnectedStore,
  rejectInvite,
} from "../../../../store/slices/restaurantsSlice";
import {
  Button,
  Dropdown,
  Form,
  FormProps,
  Popover,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import { categoryOptions } from "../../../../constants/config/category";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { BaseForm } from "../../../common/forms/BaseForm/BaseForm";

interface RestaurantCardProps {
  id: number;
  name: string;
  address: string;
  owner: string;
  phoneNumber: string;
  deliveryDate: string;
  isConnected: boolean;
}

export const RestaurantCard: React.FC<RestaurantCardProps> = ({
  id,
  name,
  address,
  owner,
  phoneNumber,
  deliveryDate,
  isConnected,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { packages } = useSelector((state: RootState) => state.packages);
  const dispatch = useDispatch();

  const options: any = [];

  packages.forEach((p) => {
    options.push({ label: `${p.name} (${p.percentage})%`, value: p.id });
  });

  const handleForm = async (values: any) => {
    try {
      const requestBody: RestaurantPackageData = {
        magasin_id: id,
        package_id: values.package,
      };
      console.log(requestBody);
      await dispatch(acceptInvite(requestBody));
    } catch (e) {
      console.error("Error accepting invite: ", e);
    }
  };

  const deleteConfirmRef = useRef<HTMLDivElement>(null);

  const handleConnect = async (id: number) => {
    try {
      const data: RestaurantData = {
        magasin_id: id,
      };
      const response = await dispatch(acceptInvite(data));
      return response;
    } catch (error) {
      console.error("Error accepting invite: ", error);
    }
  };

  const handleDeleteFunctionality = async (id: number) => {
    // exectuting different treatments based on the card type
    if (!isConnected) {
      try {
        const data: RestaurantData = {
          magasin_id: id,
        };
        const response = await dispatch(rejectInvite(data));
        return response;
      } catch (error) {
        console.error("Error rejecting invite: ", error);
      }
    } else {
      try {
        const data: RestaurantData = {
          magasin_id: id,
        };
        const response = await dispatch(deleteConnectedStore(data));
        return response;
      } catch (error) {
        console.error("Error deleting store: ", error);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteConfirmRef.current &&
        !deleteConfirmRef.current.contains(event.target as Node)
      ) {
        setShowDeleteConfirm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = async () => {
    if (showDeleteConfirm) {
      await handleDeleteFunctionality(id);
      setShowDeleteConfirm(false);
    } else {
      setClicked(true);
      setShowDeleteConfirm(true);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const { t } = useTranslation();
  const [value, setValue] = useState("normal");

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const content = (
    <div style={{ width: "10rem" }}>
      <Form layout="vertical" onFinish={handleForm}>
        <Form.Item name="package" label="package">
          <Radio.Group
            onChange={onChange}
            options={options}
            value={value}
          ></Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Ok
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <S.CardWrapper
        ref={deleteConfirmRef}
        actions={[
          <S.ActionWrapper justify={"space-between"}>
            {!isConnected ? (
              <>
                <S.DeleteBtn
                  style={{ width: showDeleteConfirm ? "100%" : "" }}
                  onClick={handleDelete}
                >
                  {showDeleteConfirm ? (
                    <>
                      <S.DeleteIcon style={{ color: "var(--heading-color)" }} />
                      Supprimer ?
                    </>
                  ) : (
                    <DeleteFilled style={{ color: "var(--heading-color)" }} />
                  )}
                </S.DeleteBtn>
                {showDeleteConfirm ? null : (
                  <Popover
                    style={{
                      paddingTop: "0",
                      zIndex: "12",
                      position: "relative",
                    }}
                    content={content}
                    trigger="click"
                    placement="right"
                  >
                    <S.AcceptBtn /* onClick={() => handleConnect(id)} */>
                      {t("restaurants.connect")}
                    </S.AcceptBtn>
                  </Popover>
                )}
              </>
            ) : (
              <>
                <S.DeleteBtn
                  style={{ width: showDeleteConfirm ? "100%" : "" }}
                  onClick={handleDelete}
                >
                  {showDeleteConfirm ? (
                    <>
                      <DeleteFilled />
                      Supprimer ?
                    </>
                  ) : (
                    <DeleteFilled style={{ color: "var(--heading-color)" }} />
                  )}
                </S.DeleteBtn>
                {showDeleteConfirm ? null : (
                  <S.AcceptBtn>{t("restaurants.show")}</S.AcceptBtn>
                )}
              </>
            )}
          </S.ActionWrapper>,
        ]}
      >
        <S.InfoWrapper>
          <S.Name>{name}</S.Name>
          <S.Info>{address}</S.Info>
          <S.Info>
            Nom :<S.InfoSpan>{owner}</S.InfoSpan>
          </S.Info>
          <S.Info>
            Tel : <S.InfoSpan>{phoneNumber}</S.InfoSpan>
          </S.Info>
          <S.BottomInfo>
            <S.Divider></S.Divider>
            <S.SmallInfoWrapper>
              <S.SmallInfoText>
                {isConnected
                  ? t("restaurants.connexionDate")
                  : t("restaurants.deliveryDate")}
              </S.SmallInfoText>
              <S.SmallInfoDate>{deliveryDate}</S.SmallInfoDate>
            </S.SmallInfoWrapper>
          </S.BottomInfo>
        </S.InfoWrapper>
      </S.CardWrapper>
    </>
  );
};
