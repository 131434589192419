import React, { useEffect, useState } from "react";
import * as S from "../Header.styles";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { NotificationsModal } from "../components/Modal/NotificationsModal";
import { LuSunMedium } from "react-icons/lu";
import { MdOutlineDarkMode } from "react-icons/md";
import { Switch } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setTheme } from "../../../store/slices/themeSlice";
import { RootState } from "../../../store/store";

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  toggleSider,
  isSiderOpened,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);

  const onChange = (checked: boolean) => {
    const newTheme = checked ? "dark" : "light";
    dispatch(setTheme(newTheme));
  };

  const { conversations } = useAppSelector(
    (state: RootState) => state.conversations
  );

  const totalUnreadCount = conversations.reduce(
    (acc, conv) => acc + conv.unreadCount,
    0
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol xs={8}>
        <S.SearchInput
          size="small"
          placeholder="Recherche"
          prefix={<SearchOutlined />}
        />
      </BaseCol>

      <S.BurgerCol>
        <LuSunMedium
          style={{
            width: "1.5rem",
            height: "auto",
            marginRight: "5px",
          }}
        />
        <Switch
          defaultChecked={theme === "dark" ? true : false}
          onChange={onChange}
        ></Switch>
        <MdOutlineDarkMode
          style={{
            width: "1.5rem",
            height: "auto",
            marginLeft: "5px",
          }}
        />
        <S.NotificationWrapper onClick={() => navigate("/conversations")}>
          <S.MessageIcon></S.MessageIcon>
          {totalUnreadCount > 0 && <S.NotificationRed></S.NotificationRed>}
        </S.NotificationWrapper>
        <S.NotificationWrapper onClick={showModal}>
          <S.NotificationIcon />
          <S.NotificationRed></S.NotificationRed>
        </S.NotificationWrapper>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
      <NotificationsModal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </BaseRow>
  );
};
