import styled from "styled-components";
import { BaseRow } from "../../components/common/BaseRow/BaseRow";
import { FONT_SIZE } from "../../styles/constants";

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const Main = styled(BaseRow)`
  display: flex;
  margin: 1rem;
  height: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 3rem;
  width: 100%;
`;
