import React, { useState, useEffect, useCallback, useRef } from "react";
import { EditableCell } from "./EditableCell";
import { useTranslation } from "react-i18next";
import { BaseOptionType } from "antd/lib/select";
import { useDispatch, useSelector } from "react-redux";
import { Ingredient } from "../../../api/ingredients.api";
import { Pagination, getEditableTableData } from "../../../api/table.api";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { BaseSpace } from "../../common/BaseSpace/BaseSpace";
import { BaseTable } from "../../common/BaseTable/BaseTable";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BasePopconfirm } from "../../common/BasePopconfirm/BasePopconfirm";
import { RootState } from "../../../store/store";
import { Modal } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import * as S from "./EditableTable.styles";
import {
  deleteIngredient,
  fetchIngredients,
  updateIngredient,
} from "../../../store/slices/ingredientsSlice";

interface EditableTableProps {
  columns:
    | Array<{
        title: string;
        dataIndex: string;
        width?: string;
        fixed?: string;
        editable?: boolean;
        render?: (text: string, record: Ingredient) => React.ReactElement;
      }>
    | [];
  unitOptions?: BaseOptionType[];
  categoryOptions?: BaseOptionType[];
}

export const EditableTable: React.FC<EditableTableProps> = ({
  columns,
  unitOptions,
  categoryOptions,
}) => {
  const {
    ingredients: { produits },
    loading,
  } = useSelector((state: RootState) => state.ingredients);
  const [form] = BaseForm.useForm();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const [tableData, setTableData] = useState<{
    data: Ingredient[];
    loading: any;
  }>({
    data: produits || [],
    loading: loading,
  });
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 5,
    position: ["bottomCenter"],
  });
  const [editingKey, setEditingKey] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchIngredients());
  }, [dispatch]);

  useEffect(() => {
    if (produits?.length > 0) {
      fetch();
    }
  }, [produits, pagination]);

  const fetch = useCallback(() => {
    setTableData((prevTableData) => ({ ...prevTableData, loading: true }));
    getEditableTableData(produits).then((res: any) => {
      if (isMounted.current) {
        setTableData((prevTableData) => ({
          ...prevTableData,
          ...res,
          loading: false,
        }));
      }
    });
  }, [isMounted, produits, pagination]);

  const handleTableChange = (newPagination: Pagination) => {
    setPagination(newPagination);
  };

  const isEditing = (record: Ingredient) => record.id === editingKey;

  const edit = (record: Ingredient) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };
  const categoriesMap: { [categoryKey: string]: number } = {
    Viande: 1,
    Poulet: 2,
    Boissons: 3,
    "Fruits et légumes": 4,
  };
  const save = async (key: number) => {
    try {
      //TODO temporary fix this will create an anomaly when a category is added
      const row = (await form.validateFields()) as Ingredient;
      console.log(row);
      const newData = [...tableData.data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item: Ingredient = newData[index];

        const updatedIngredient: any = {
          id: key,
          name: row.name,
          quantity: row.quantity,
          prix: row.prix,
          statu: row.statu,
          moq: row.moq,
          limit: row.limit,
          unity: row.unity,
          note: row.note,
          fournisseur_categories_id: categoriesMap[row.category],
          fournisseur_id: row.fournisseur_id,
        };
        dispatch(updateIngredient(updatedIngredient));
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        console.error("Index not found for key:", key);
      }
      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
    } catch (errInfo) {
      console.error("Validate Failed:", errInfo);
    }
  };

  const handleDeleteRow = async (rowId: number) => {
    let isModalVisible = true;

    const closeAndDelete = () => {
      isModalVisible = false;
      modal.update({
        visible: false,
      });
      deleteData(rowId);
    };

    const cancel = () => {
      isModalVisible = false;
      modal.update({
        visible: false,
        open: false,
      });
    };

    const modal = Modal.confirm({
      title: "Supprimer",
      content: "Êtes-vous sûr de vouloir supprimer cet ingrédient ?",
      visible: isModalVisible,
      onOk: closeAndDelete,
      cancelText: "Annuler",
      onCancel: cancel,
    });
  };

  const deleteData = async (rowId: number) => {
    try {
      const item: Ingredient | undefined = tableData.data.find(
        (item) => item.id === rowId
      );
      if (item) {
        await dispatch(deleteIngredient(item.id));
        setTableData({
          ...tableData,
          data: tableData.data.filter((item) => item.id !== rowId),
        });
      } else {
        console.error("Item not found for key:", rowId);
      }
    } catch (error) {
      console.error("Error deleting ingredient:", error);
    }
  };

  const actionColumns = [
    {
      title: t("tables.actions"),
      dataIndex: "actions",
      width: "22%",
      render: (text: string, record: Ingredient) => {
        const editable = isEditing(record);
        return (
          <BaseSpace>
            {editable ? (
              <>
                <BaseButton type="primary" onClick={() => save(record.id)}>
                  Sauvegarder
                </BaseButton>
                <BasePopconfirm
                  title={t("tables.cancelInfo")}
                  onConfirm={cancel}
                >
                  <BaseButton type="default">Annuler</BaseButton>
                </BasePopconfirm>
              </>
            ) : (
              <>
                <BaseButton
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                  }}
                  type="default"
                  disabled={editingKey !== 0}
                  onClick={() => edit(record)}
                >
                  <EditOutlined />
                </BaseButton>
                <BaseButton
                  type="default"
                  danger
                  onClick={() => handleDeleteRow(record.id)}
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                  }}
                >
                  <CloseOutlined />
                </BaseButton>
              </>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  const allColumns = [...columns, ...actionColumns];

  const mergedColumns = allColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Ingredient) => {
        const cellProps: any = {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          options: col.dataIndex === "unity" ? unitOptions : categoryOptions,
        };

        if (col.dataIndex === "quantity" || col.dataIndex === "limit") {
          cellProps.inputType = "number";
        } else if (col.dataIndex === "unity") {
          cellProps.inputType = "unitSelect";
        } else if (col.dataIndex === "category") {
          cellProps.inputType = "categorySelect";
        } else if (col.dataIndex === "image") {
          cellProps.inputType = "file";
        } else {
          cellProps.inputType = "text";
        }

        return cellProps;
      },
    };
  });
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <BaseForm form={form} component={false}>
      <S.IngrendientsTable
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        //rowSelection={rowSelection}
        dataSource={tableData.data}
        rowKey={"id"}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          ...pagination,
          total: tableData?.data.length,
        }}
        onChange={handleTableChange}
        loading={tableData.loading}
        style={{ borderRadius: "5px", overflowX: "auto" }}
      />
    </BaseForm>
  );
};
