import styled from "styled-components";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { FONT_SIZE } from "../../../styles/constants";

export const StyledRow = styled(BaseRow)`
  & :first-child {
    & .ant-card {
      background-color: #c9caef;

      & .ant-card-body div .ant-typography {
        color: #111315;
      }

      & .ant-card-body {
        & .ant-row div:first-child {
          background-color: #f8f8f8;
        }
        & .ant-row .ant-btn {
          background-color: #f8f8f8;
        }
      }
    }
  }
  margin-bottom: 5px;
  & :nth-last-child(-n + 2) {
    & .ant-card .ant-card-body div .ant-typography {
      margin-bottom: 1rem;
      font-size: ${FONT_SIZE.xl};
    }
    & .ant-card .ant-card-body .ant-row {
      margin-bottom: 2rem;
    }
  }
`;
