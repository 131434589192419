import { useTranslation } from "react-i18next";
import { BaseTypography } from "../common/BaseTypography/BaseTypography";
import * as S from "./Conversations.styles";
import { FONT_SIZE } from "../../styles/constants";
import { Input } from "antd";
import { SearchIcon } from "../header/components/HeaderSearch/HeaderSearch.styles";
import { ConversationForm } from "./ConversationForm/ConversationForm";
import { MessageFilled, SearchOutlined } from "@ant-design/icons";
import { ProfileCard } from "./RestaurantProfile/RestaurantProfile.styles";
import { RestaurantProfile } from "./RestaurantProfile/RestaurantProfile";
import { useEffect, useState } from "react";
import { ConversationMessages } from "./Conversationmessages/ConversationMessages";
import {
  fetchConversations,
  fetchSelectedConversation,
  resetMessages,
  setSelectedConversation,
} from "../../store/slices/conversationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Conversation } from "../../api/conversations.api";
export const Conversations = () => {
  const { t } = useTranslation();
  const [activeProfileId, setActiveProfileId] = useState(null);

  const dispatch = useDispatch();
  const { conversations, loading } = useSelector(
    (state: RootState) => state.conversations
  );
  const [discussionTitle, setDiscussionTitle] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredConversations, setFilteredConversations] =
    useState(conversations);

  const handleProfileClick = (id: any, name: string) => {
    setActiveProfileId(id === activeProfileId ? null : id);
    setDiscussionTitle(name);
    dispatch(fetchSelectedConversation(id));
    dispatch(setSelectedConversation(id));
  };

  useEffect(() => {
    setDiscussionTitle("");
    dispatch(resetMessages());
    const filtered = conversations.filter((conversation) =>
      conversation.magasin_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setFilteredConversations(filtered);
  }, [searchQuery, conversations]);

  function parseDateTime(dateTimeString: string): Date {
    const parts = dateTimeString.split(" ");
    const timeParts = parts[0].split(":");
    const dateParts = parts[1].split("-");
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    return new Date(year, month - 1, day, hour, minute);
  }

  useEffect(() => {
    dispatch(fetchConversations());
  }, []);

  return (
    <S.Main>
      <S.FlexRow>
        <BaseTypography style={{ fontSize: FONT_SIZE.xxxxl }}>
          {t("common.menu.conversations")}
        </BaseTypography>
      </S.FlexRow>
      <S.DiscussionsBox>
        <S.DiscussionListWrapper>
          <S.DiscussionList>
            {filteredConversations.map((c: Conversation) => {
              const conversationDateTime = parseDateTime(c.time);
              const today = new Date();
              const isToday =
                conversationDateTime.getFullYear() === today.getFullYear() &&
                conversationDateTime.getMonth() === today.getMonth() &&
                conversationDateTime.getDate() === today.getDate();

              const displayTime = isToday
                ? conversationDateTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : conversationDateTime.toLocaleDateString();

              return (
                <RestaurantProfile
                  id={c.id}
                  name={c.magasin_name}
                  lastMessage={c.latestMessage}
                  unreadCount={c.unreadCount}
                  time={displayTime}
                  image={null}
                  isactive={activeProfileId === c.id}
                  onClick={() => handleProfileClick(c.id, c.magasin_name)}
                ></RestaurantProfile>
              );
            })}
          </S.DiscussionList>
          <S.SearchInput
            placeholder="Rechercher les contacts"
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          ></S.SearchInput>
        </S.DiscussionListWrapper>
        <S.Discussion>
          {discussionTitle != "" ? (
            <>
              <S.DiscussionTitle>{discussionTitle}</S.DiscussionTitle>
              <ConversationMessages />
            </>
          ) : (
            <>
              <S.ConversationStarter>
                <S.MessageIcon></S.MessageIcon>
                <BaseTypography style={{ fontSize: `${FONT_SIZE.xxxxl}` }}>
                  Conversations
                </BaseTypography>
                <BaseTypography>
                  Séléctionnez une conversation pour envoyer un message
                </BaseTypography>
              </S.ConversationStarter>
            </>
          )}
        </S.Discussion>
      </S.DiscussionsBox>
    </S.Main>
  );
};
