import React from "react";
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from "./styles/GlobalStyle";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import { AppRouter } from "./components/router/AppRouter";
import { useLanguage } from "./hooks/useLanguage";
import deDe from 'antd/lib/locale/de_DE';
import { themeObject } from "./styles/themeVariables";
import { useAppSelector } from "./hooks/reduxHooks";
import { useAutoNightMode } from "./hooks/useAutoNightMode";
import { useThemeWatcher } from "./hooks/useThemeWatcher";

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
