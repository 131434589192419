import { useTranslation } from "react-i18next";
import { BaseRow } from "../common/BaseRow/BaseRow";
import { BaseCol } from "../common/BaseCol/BaseCol";
import { DatePicker, Tabs, TabsProps } from "antd";
import { BaseTypography } from "../common/BaseTypography/BaseTypography";
import * as S from "./Settings.styles";
import { DownOutlined } from "@ant-design/icons";
import { FONT_SIZE } from "../../styles/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { GoPackage } from "react-icons/go";
import { ProfileSettings } from "./GeneraleSettings/ProfileSettings";
import { TbTruckDelivery } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { DeliverySettings } from "./DeliverySettings/DeliverySettings";
import { PacksSettings } from "./PacksSettings/PacksSettings";
import { useEffect } from "react";
import { fetchSettings } from "../../store/slices/settingsSlice";

export const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  const onChange = (key: string) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <CgProfile style={{ marginRight: "5px" }} />
          Profile
        </span>
      ),
      children: <ProfileSettings />,
    },
    {
      key: "2",
      label: (
        <span>
          <TbTruckDelivery style={{ marginRight: "5px" }} />
          Livraison
        </span>
      ),
      children: <DeliverySettings/>,
    },
    {
      key: "3",
      label: (
        <span>
          <GoPackage style={{ marginRight: "5px" }} />
          Paquets
        </span>
      ),
      children: <PacksSettings />,
    },
  ];

  return (
    <S.Main>
      <S.FlexRow>
        <BaseTypography style={{ fontSize: FONT_SIZE.xxxxl }}>
          {t("common.menu.settings")}
        </BaseTypography>
      </S.FlexRow>
      <BaseRow style={{ width: "100%" }}>
        <Tabs
          style={{ width: "100%" }}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </BaseRow>
    </S.Main>
  );
};
