import * as S from "./RestaurantProfile.styles";
import defaultProfileImage from "../../../assets/images/stub-avatar.webp";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../../styles/constants";
interface Props {
  id: number;
  image: string | null;
  name: string;
  time: string;
  lastMessage: string;
  unreadCount: number;
  isactive: any;
  onClick: any;
}

export const RestaurantProfile: React.FC<Props> = ({
  id,
  image,
  name,
  time,
  lastMessage,
  unreadCount,
  isactive,
  onClick,
}) => {
  return (
    <S.ProfileCard isactive={isactive} onClick={() => onClick(id)}>
      <S.StyledImage
        src={
          image
            ? process.env.REACT_APP_IMAGE_URL + "magasins_logo/" + image
            : defaultProfileImage
        }
        alt="profile image"
      />
      <S.Details>
        <S.Name>{name}</S.Name>
        <S.LastMessage
          style={
            unreadCount > 0
              ? { color: "#1da57a" }
              : { color: "var(--text-main-color)" }
          }
        >
          {lastMessage}
        </S.LastMessage>
      </S.Details>
      {unreadCount > 0 ? (
        <S.InfoWrapper>
          <BaseTypography style={{ fontSize: `${FONT_SIZE.xxs}` }}>
            {time}
          </BaseTypography>
          <S.NumberCount>
            <BaseTypography>{unreadCount}</BaseTypography>
          </S.NumberCount>
        </S.InfoWrapper>
      ) : (
        <S.InfoWrapper>
          <BaseTypography style={{ fontSize: `${FONT_SIZE.xxs}` }}>
            {time}
          </BaseTypography>
        </S.InfoWrapper>
      )}
    </S.ProfileCard>
  );
};
