import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { Inventory } from "../../components/inventory/Inventory";

const InventoryPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("common.menu.inventory")}</PageTitle>
      <Inventory />
    </>
  );
};

export default InventoryPage;
