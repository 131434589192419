import styled from "styled-components";
import { BaseRow } from "../common/BaseRow/BaseRow";
import { Input } from "antd";
import { BaseTypography } from "../common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../styles/constants";
import { MessageFilled } from "@ant-design/icons";

export const Main = styled(BaseRow)`
  display: flex;
  height: calc(100vh - 70.2px);
  margin: 0 1rem;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 3rem;
  width: 100%;
`;

export const DiscussionsBox = styled.div`
  height: calc(100% - 41px);
  width: 100%;
  background-color: var(--card-background-color);
  border-radius: 7px;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const DiscussionListWrapper = styled.div`
  height: 100%;
  width: 30%;
  //border: 1px solid var(--text-sider-secondary-color);
  border-right: none;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 30%;
    flex-direction: column-reverse;
    overflow: auto;
  }
`;
export const DiscussionList = styled.div`
  padding-top: 1rem;
  overflow-y: auto;
  flex: 1;

  @media screen and (max-width: 900px) {
    display: flex;
    padding-top: 0;
    padding: 1rem;
    gap: 1rem;
    overflow-x: auto;
  }
`;

export const Discussion = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--text-sider-secondary-color);
  @media screen and (max-width: 900px) {
    border-left: none;
    height: 70%;
    width: 100%;
  }
`;

export const SearchInput = styled(Input)`
  border-radius: 0;
  border: none;
  height: 3rem;
  border-color: var(--text-sider-secondary-color);
  border-top: 1px solid var(--text-sider-secondary-color);
  background-color: transparent;

  & input {
    background-color: transparent;

    &:hover {
      border: none;
    }
  }

  @media screen and (max-width: 900px) {
    border-top: none;
    border-bottom: 1px solid var(--text-sider-secondary-color);
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: var(--text-sider-secondary-color);
  }
`;

export const ConversationWrapper = styled.div`
  flex: 1;
`;

export const DiscussionTitle = styled.div`
  height: 3rem;
  border-bottom: 1px solid var(--text-sider-secondary-color);
  font-size: ${FONT_SIZE.xxxl};
  width: 100%;
  padding: 1rem;
  text-align: left;
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    border-top: 1px solid var(--text-sider-secondary-color);
  }
`;

export const ConversationStarter = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const MessageIcon = styled(MessageFilled)`
  padding: 1rem;
  & svg {
    height: 3rem;
    width: 3rem;
    fill: #1da57a;
  }
`;
