import styled from "styled-components";
import { Card } from "antd";
import { BaseImage } from "../../common/BaseImage/BaseImage";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import {
  FONT_SIZE,
  media,
  FONT_WEIGHT,
  BASE_COLORS,
} from "../../../styles/constants";

export const SummaryCard = styled(Card)`
  width: 17rem;
  height: 10rem;
  border: none;
`;

export const CardBody = styled.div`
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  text-align: left;
`;

export const CardNumber = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxxl};
  text-align: right;
`;
