import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changeStatusOrderApi,
  deleteOrderApi,
  deleteOrderProductApi,
  fetchOrderDetailsApi,
  fetchOrdersApi,
  orderData,
  orderProductData,
  orderStatutData,
} from "../../api/orders.api";
import { OrderDetail, OrderList } from "../../domain/ordersModel";

interface OrdersState {
  pendingOrders: { demandes: [] };
  currentOrders: { demandes: [] };
  placedOrders: { demandes: [] };
  orderDetails: {
    orderDetailsInfo: OrderDetail;
    loading: "idle" | "pending" | "succeeded" | "failed";
  };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: OrdersState = {
  pendingOrders: { demandes: [] },
  currentOrders: { demandes: [] },
  placedOrders: { demandes: [] },
  orderDetails: {
    orderDetailsInfo: {
      produits: [],
      commande: {
        numCommande: 0,
        date_souhaitee: "",
        commentaire: "",
        created_at: "",
        magasin_id: 0,
        name: "",
        email: "",
        phone: "",
        restaurant: "",
        adresse: "",
      },
    },
    loading: "idle",
  },
  loading: "idle",
};

export const getPendingOrders: any = createAsyncThunk(
  "orders/getPendingOrders",
  async () => {
    const orders: OrderList = await fetchOrdersApi("/commandeEnAttente");
    return orders;
  }
);

export const getCurrentOrders: any = createAsyncThunk(
  "orders/getCurrentOrders",
  async () => {
    const orders: OrderList = await fetchOrdersApi("/commandeEnCours");
    return orders;
  }
);

export const getPlacedOrders: any = createAsyncThunk(
  "orders/getPlacedOrders",
  async () => {
    const orders: OrderList = await fetchOrdersApi("/commandePassee");
    return orders;
  }
);

export const deleteOrder: any = createAsyncThunk(
  "orders/deleteOrder",
  async (orderData: orderData, { dispatch }) => {
    const response = await deleteOrderApi(orderData, "/deleteCommande");
    dispatch(getPendingOrders());
    dispatch(getCurrentOrders());
    dispatch(getPlacedOrders());
    return response;
  }
);

export const changeStatut: any = createAsyncThunk(
  "orders/changeStatut",
  async (orderData: orderStatutData, { dispatch }) => {
    const response = await changeStatusOrderApi(orderData, "/changeStaut");
    dispatch(getCurrentOrders());
    dispatch(getPendingOrders());
    dispatch(getPlacedOrders());
    return response;
  }
);

export const fetchOrderDetails: any = createAsyncThunk(
  "orders/fetchOrderDetails",
  async (id: number, { rejectWithValue }) => {
    try {
      const orderDetails: OrderDetail = await fetchOrderDetailsApi(
        "/detailsCommande",
        id
      );
      return orderDetails;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOrderProduct: any = createAsyncThunk(
  "orders/deleteOrderProduct",
  async (data: orderProductData, { dispatch }) => {
    const response = await deleteOrderProductApi(
      data,
      "/supprimerProduitCommande"
    );
    dispatch(fetchOrderDetails(data.commande_id));
    return response;
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingOrders.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.pendingOrders = action.payload;
      })
      .addCase(getPendingOrders.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getCurrentOrders.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCurrentOrders.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.currentOrders = action.payload;
      })
      .addCase(getCurrentOrders.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getPlacedOrders.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getPlacedOrders.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.placedOrders = action.payload;
      })
      .addCase(getPlacedOrders.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.orderDetails.loading = "pending";
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.orderDetails.loading = "succeeded";
        state.orderDetails.orderDetailsInfo = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state) => {
        state.orderDetails.loading = "failed";
      })
      .addCase(deleteOrderProduct.pending, (state) => {
        state.orderDetails.loading = "pending";
      })
      .addCase(deleteOrderProduct.fulfilled, (state) => {
        state.orderDetails.loading = "succeeded";
      })
      .addCase(deleteOrderProduct.rejected, (state) => {
        state.orderDetails.loading = "failed";
      });
  },
});

export default ordersSlice.reducer;
