import { OrderDetail, OrderList } from "../domain/ordersModel";
import { readToken } from "../services/localStorage.service";
import axios, { AxiosError, AxiosResponse } from "axios";

export interface orderData {
  id: number;
}

export interface orderStatutData {
  id: number;
  statut: string;
}
export interface orderProductData {
  produit_id: number;
  commande_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const fetchOrdersApi = async (apiUrl: string): Promise<OrderList> =>
  await axiosInstance
    .get<OrderList>(apiUrl)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("fetch all commandes failed", error);
      throw error.response?.data || "fetch all commandes failed";
    });

export const deleteOrderApi = async (
  orderData: orderData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      apiUrl,
      { data: orderData }
    );
    return response.data;
  } catch (error: any) {
    console.error("Delete order failed", error);
    throw error.response?.data || "Delete order failed";
  }
};

export const changeStatusOrderApi = async (
  orderData: orderStatutData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put<any>(
      apiUrl,
      orderData
    );
    return response.data;
  } catch (error: any) {
    console.error("Change Statut failed", error);
    throw error.response?.data || "Change Statut failed";
  }
};

export const fetchOrderDetailsApi = async (
  apiUrl: string,
  orderId: number
): Promise<OrderDetail> =>
  await axiosInstance
    .get<OrderDetail>(apiUrl + "/" + orderId)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("fetch order details failed", error);
      throw error.response?.data || "fetch order details failed";
    });

export const deleteOrderProductApi = async (
  data: orderProductData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      apiUrl,
      {
        data: data,
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Delete order product failed", error);
    throw error.response?.data || "Delete order product failed";
  }
};
