import { Card } from "antd";
import styled from "styled-components";

export const TopProductsCard = styled(Card)`
  width: 100%;
  height: 18rem;
  position: relative;
  border: 1px solid var(--text-sider-secondary-color);
  background-color: transparent;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
