import { Button, Image, Input, Upload, UploadProps, FormProps } from "antd";
import { BaseCard } from "../../common/BaseCard/BaseCard";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import * as S from "./ProfileSettings.styles";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { useState } from "react";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "../../../store/slices/settingsSlice";
import { RootState } from "../../../store/store";

export const ProfileSettings: React.FC = () => {
  const [form] = BaseForm.useForm();
  const dispatch = useDispatch();
  const { settingsInfo } = useSelector((state: RootState) => state.settings);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const propsBG: UploadProps = {
    name: "bg",
    beforeUpload: () => {
      return false;
    },
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    accept: ".jpeg,.jpg,.png",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        //message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsImage: UploadProps = {
    name: "logo",
    beforeUpload: () => {
      return false;
    },
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    accept: ".jpeg,.jpg,.png",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        //message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const handleForm: FormProps["onFinish"] = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key == "logo") {
          formData.append("logo", values.logo.file);
        } else if (key == "bg") {
          formData.append("bg", values.bg.file);
        } else {
          formData.append(key, values[key]);
        }
      }
    });

    dispatch(updateDetails(formData));
    setIsEditable((isEditable) => !isEditable);
  };

  const handleEditBtn = () => {
    setIsEditable((isEditable) => !isEditable);
  };

  const handleCancelBtn = () => {
    setIsEditable((isEditable) => !isEditable);
  };
  return (
    <S.CardContent>
      <S.Title>Paramètres du profile</S.Title>
      <S.Description>
        Mettez à jour votre profil et comment les gens peuvent vous contacter en
        général
      </S.Description>
      <S.EditButton
        style={isEditable ? { display: "none" } : undefined}
        size="small"
        onClick={handleEditBtn}
        type="primary"
      >
        Modifier
      </S.EditButton>
      <BaseRow style={{ width: "100%" }} justify={"space-between"}>
        <S.StyledForm
          layout="vertical"
          form={form}
          onFinish={handleForm}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <BaseRow style={{ width: "100%" }} justify={"space-between"}>
            <BaseCol xs={12} md={12} lg={12} span={12}>
              <BaseForm.Item label="Image du profile" name="logo">
                {isEditable ? (
                  <Upload {...propsImage} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                ) : (
                  <Image
                    width={100}
                    height={100}
                    preview={false}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "fournisseurs_logo/" +
                      settingsInfo?.details_fournisseur?.logo
                    }
                  />
                )}
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={12} md={12} lg={12} span={12}>
              <BaseForm.Item label="Arrière-plan du profile" name="bg">
                {isEditable ? (
                  <Upload {...propsBG} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                ) : (
                  <Image
                    height={100}
                    preview={false}
                    style={{
                      objectFit: "cover",
                      borderRadius: "7px",
                      width: "100%",
                    }}
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "fournisseurs_bg/" +
                      settingsInfo?.details_fournisseur?.bg
                    }
                  />
                )}
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow
            style={{ width: "100%" }}
            gutter={16}
            justify={"space-between"}
          >
            <BaseCol md={8} xs={24} span={8}>
              <BaseForm.Item label="Nom" name="name">
                <Input
                  type="text"
                  placeholder={settingsInfo?.name}
                  disabled={!isEditable}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol md={8} xs={24} span={8}>
              <BaseForm.Item label="Email" name="email">
                <Input
                  type="email"
                  placeholder={settingsInfo?.email}
                  disabled={!isEditable}
                ></Input>
              </BaseForm.Item>
            </BaseCol>
            <BaseCol md={8} xs={24} span={8}>
              <BaseForm.Item label="Téléphone" name="phone">
                <Input
                  type="number"
                  placeholder={settingsInfo?.phone}
                  disabled={!isEditable}
                ></Input>
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow
            style={{ width: "100%" }}
            gutter={16}
            justify={"space-between"}
          >
            <BaseCol md={12} xs={24} span={12}>
              <BaseForm.Item label="Adresse" name="adresse">
                <Input
                  type="text"
                  placeholder={settingsInfo?.adresse}
                  disabled={!isEditable}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol md={12} xs={24} span={12}>
              <BaseForm.Item label="Ville" name="ville">
                <Input
                  type="text"
                  placeholder={settingsInfo?.ville}
                  disabled={!isEditable}
                ></Input>
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow
            style={{
              width: "100%",
              display: isEditable ? "flex" : "none",
            }}
            justify={"end"}
          >
            <BaseButton htmlType="submit" size="small" type="primary">
              Confirmer
            </BaseButton>
            <BaseButton
              onClick={handleCancelBtn}
              style={{ marginLeft: "1rem" }}
              type="primary"
              size="small"
              danger
            >
              Annuler
            </BaseButton>
          </BaseRow>
        </S.StyledForm>
      </BaseRow>
    </S.CardContent>
  );
};
