import { useEffect } from "react";
import { FONT_SIZE } from "../../../styles/constants";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "./ChartDashboard.styles";
import * as echarts from "echarts";

interface ChartProps {
  title: string;
  data: number[];
}
const { Meta } = S.ChartCard;

export const ChartDashboard: React.FC<ChartProps> = ({ title, data }) => {
  useEffect(() => {
    const chartDom = document.getElementById("main")!;
    const myChart = echarts.init(chartDom);

    const option: echarts.EChartsOption = {
      title: {
        show: false,
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: "category",
        data: ["", "", "", "", "", "", ""],
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          show: false,
          lineStyle: {
            type: "dotted",
            dashOffset: 5,
          },
        },
        splitLine: {
          lineStyle: {
            width: 1,
            type: "dotted",
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          data: data,
          type: "bar",
          barWidth: "40%",
          itemStyle: {
            color: "#C2E9DD",
          },
        },
      ],
    };

    option && myChart.setOption(option);

    // Cleanup: Dispose the chart when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, [data]);
  return (
    <>
      <S.ChartCard>
        <BaseTypography>{title}</BaseTypography>
        <Meta description={<div id="main" style={{ height: "17rem" }}></div>} />
      </S.ChartCard>
    </>
  );
};
