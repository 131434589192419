import React from "react";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import * as S from "./OrdersPage.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CurrentOrders } from "../../components/orders/currentOrders/CurrentOrders";

const CurrentOrdersPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const theme = useSelector((state: RootState) => state.theme.theme);

  return (
    <>
      <PageTitle>Current Orders</PageTitle>
      <CurrentOrders />
    </>
  );
};

export default CurrentOrdersPage;
