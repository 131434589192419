import styled, { css } from "styled-components";
import { media } from "../../../../styles/constants";
import { BaseLayout } from "../../../common/BaseLayout/BaseLayout";

interface HeaderProps {
  $isTwoColumnsLayout: boolean;
}

export default styled(BaseLayout.Content)<HeaderProps>`
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  @media only screen and ${media.md} {
    padding: 0;
  }

  @media only screen and ${media.xl} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        padding: 0;
      `}
  }
`;
