import { Button, Card } from "antd";
import styled from "styled-components";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../../styles/constants";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";

export const CardContent = styled(Card)`
  width: 100%;
  position: relative;
  border: none;
  padding: 1rem;
  background-color: var(--card-background-color);
  .ant-card-head {
    border: none;
  }
  .ant-card-actions {
    background-color: transparent;
    border: none;
    margin-top: -1rem;
  }
`;

export const Title = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxxl};
`;

export const Description = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
`;

export const EditButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const StyledForm = styled(BaseForm)`
  & .ant-form-item-label > label {
    color: #1b936e;
  }
`;

export const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > .ant-table-cell {
    background-color: var(--layout-header-bg-color);
    color: var(--text-main-color);
    border-right: none;
  }
  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > .ant-table-cell {
    background-color: var(--layout-header-bg-color);
    color: var(--text-main-color);
    border-right: none;
  }
  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    .ant-table-row-selected
    td {
    background-color: grey;
  }

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    .ant-table-row:nth-child(2n)
    td {
    background-color: var(--card-background-color);
  }

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    border-right: 1px solid;
  }
`;
