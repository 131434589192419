import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { Card } from "../Card/Card";
import dollars from "../../../assets/icons/dashboard/dollars.png";
import scaleUp from "../../../assets/icons/dashboard/scaleUp.png";
import vMark from "../../../assets/icons/dashboard/vMark.png";
import time from "../../../assets/icons/dashboard/time.png";
import timeLight from "../../../assets/icons/dashboard/timeLight.png";
import vMarkLight from "../../../assets/icons/dashboard/vMarkLight.png";
import scaleUpLight from "../../../assets/icons/dashboard/scaleLight.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useResponsive } from "../../../hooks/useResponsive";
import { useEffect } from "react";
import { getStatistiques } from "../../../store/slices/dashboardSlice";
import * as S from "./DashboardSuammary.styles";

const DashboardSummary: React.FC = () => {
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.theme.theme);
  const { revenu, commandesImpayées, commandesPayées, pourcentage } =
    useSelector((state: RootState) => state.dashboard);

  const vMarkIcon = theme === "dark" ? vMark : vMarkLight;
  const timeIcon = theme === "dark" ? time : timeLight;
  const scaleUpIcon = theme === "dark" ? scaleUp : scaleUpLight;

  const desktopVersion = (
    <S.StyledRow gutter={[16, 16]} style={{ width: "100%" }}>
      <BaseCol span={6}>
        <Card
          title="Revenu"
          numberResult={revenu}
          icon={dollars}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol span={6}>
        <Card
          title="Commandes prépayées"
          numberResult={commandesPayées}
          icon={vMarkIcon}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol span={6}>
        <Card
          title="Commandes impayées"
          numberResult={commandesImpayées}
          icon={timeIcon}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol span={6}>
        <Card
          title="Performance de livraison"
          numberResult={pourcentage}
          icon={scaleUpIcon}
          unit="%"
        ></Card>
      </BaseCol>
    </S.StyledRow>
  );

  const mobileAndTabletVersion = (
    <S.StyledRow style={{ width: "100%" }}>
      <BaseCol
        span={12}
        style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
      >
        <Card
          title="Revenu"
          numberResult={revenu}
          icon={dollars}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol
        span={12}
        style={{ padding: 10, paddingBottom: 5, paddingRight: 0 }}
      >
        <Card
          title="Commandes prépayées"
          numberResult={commandesPayées}
          icon={vMarkIcon}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol span={12} style={{ padding: 10, paddingTop: 5, paddingLeft: 0 }}>
        <Card
          title="Commandes impayées"
          numberResult={commandesImpayées}
          icon={timeIcon}
          unit="DHS"
        ></Card>
      </BaseCol>

      <BaseCol
        span={12}
        style={{ padding: 10, paddingTop: 5, paddingRight: 0 }}
      >
        <Card
          title="Performance de livraison"
          numberResult={pourcentage}
          icon={scaleUpIcon}
          unit="%"
        ></Card>
      </BaseCol>
    </S.StyledRow>
  );

  return isDesktop ? desktopVersion : mobileAndTabletVersion;
};

export default DashboardSummary;
