import { css } from "styled-components";
import { BASE_COLORS } from "./constants";
import { ThemeType } from "../interfaces/interfaces";
import { hexToRGB } from "../utils/utils";
import { antDarkColorsTheme, darkColorsTheme } from "./themes/darkTheme";
import { lightColorsTheme } from "./themes/lightTheme";

export const themeObject: any = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

export const antThemeObject = {
  light: {},
  dark: antDarkColorsTheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primary};
  --info-color: var(--primary-color);
  --secondary-color: ${themeObject[theme].secondary};
  --error-color: ${themeObject[theme].error};
  --warning-color: ${themeObject[theme].warning};
  --success-color: ${themeObject[theme].success};
  --background-color: ${themeObject[theme].background};
  --secondary-background-color: ${themeObject[theme].secondaryBackground};
  --card-background-color: ${themeObject[theme].cardBackground};
  --drawer-card-background-color: ${themeObject[theme].drawerCardBackground};
  --input-background-color: ${themeObject[theme].additionalBackground};
  --icon-background-color: ${themeObject[theme].iconBackgroundColor};
  --spinner-base-color: ${themeObject[theme].spinnerBase};
  --shadow-color: ${themeObject[theme].shadow};
  --border-color: ${themeObject[theme].border};
  --scroll-color: ${themeObject[theme].scroll};
  --primary-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --active-sider-link-bg: ${themeObject[theme].ActiveSiderLinkBg};

  --text-main-color: ${themeObject[theme].textMain};
  --text-light-color: ${themeObject[theme].textLight};
  --text-superLight-color: ${themeObject[theme].textSuperLight};
  --text-secondary-color: ${themeObject[theme].textSecondary};
  --text-sider-secondary-color: ${themeObject[theme].textSiderSecondary};
  --text-sider-active-color: ${themeObject[theme].siderActiveLink};

  --notification-success-color: ${themeObject[theme].notificationSuccess};
  --notification-primary-color: ${themeObject[theme].notificationPrimary};
  --notification-warning-color: ${themeObject[theme].notificationWarning};
  --notification-error-color: ${themeObject[theme].notificationError};

  --icon-color: ${themeObject[theme].icon};
  --icon-hover-color: ${themeObject[theme].iconHover};
  --box-shadow: ${themeObject[theme].boxShadow};

  --heading-color: ${themeObject[theme].heading};
  --item-hover-bg: ${themeObject[theme].itemHoverBg};
  --background-base-color: ${themeObject[theme].backgroundColorBase};
  --border-base-color: ${themeObject[theme].borderBase};
  --disabled-color: ${themeObject[theme].disable};
  --layout-body-bg-color: ${themeObject[theme].layoutBodyBg};
  --layout-header-bg-color: ${themeObject[theme].layoutHeaderBg};
  --layout-sider-bg-color: ${themeObject[theme].layoutSiderBg};
  --input-placeholder-color: ${themeObject[theme].inputPlaceholder};
  --input-background-color: ${themeObject[theme].inputBackground};
  --avatar-bg: ${themeObject[theme].avatarBg};
  --alert-text-color: ${themeObject[theme].alertTextColor};
`;

export const lightThemeVariables = css`
  ${getThemeVariables("light")}
`;

export const darkThemeVariables = css`
  ${getThemeVariables("dark")}
  --ant-success-color-deprecated-border: ${antThemeObject["dark"]
    .successBorder} !important;
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;
