import React from "react";

import { IoStorefrontOutline } from "react-icons/io5";
import { TbStatusChange } from "react-icons/tb";
import { GrStatusGood } from "react-icons/gr";
import { GrStatusUnknown } from "react-icons/gr";
import { FiSettings } from "react-icons/fi";
import { MdOutlineInventory } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiDashboard3Line } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { BiConversation } from "react-icons/bi";

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "common.menu.dashboard",
    key: "dashboard",
    // TODO use path variable
    url: "/",
    icon: <RiDashboard3Line />,
  },
  {
    title: "common.menu.reporting",
    key: "reporting",
    url: "/reporting",
    icon: <HiOutlineDocumentReport />,
  },
  {
    title: "common.menu.pendingOrders",
    // TODO changing keys and urls
    key: "pending-order",
    url: "/pending-orders",
    icon: <GrStatusUnknown />,
  },
  {
    title: "common.menu.OrdersinProgress",
    key: "current-orders",
    url: "/current-orders",
    icon: <TbStatusChange />,
  },
  {
    title: "common.menu.OrdersPlaced",
    key: "palced-orders",
    url: "/palced-orders",
    icon: <GrStatusGood />,
  },
  {
    title: "common.menu.restaurants",
    key: "restaurants",
    url: "/restaurants",
    icon: <IoStorefrontOutline />,
  },
  // {
  //   title: "common.menu.Allrestaurants",
  //   key: "allRestaurants",
  //   url: "/allRestaurants",
  //   icon: <IoStorefrontOutline />,
  // },
  {
    title: "common.menu.inventory",
    key: "inventory",
    url: "/inventory",
    icon: <MdOutlineInventory />,
  },

  {
    title: "common.menu.settings",
    key: "settings",
    url: "/settings",
    icon: <FiSettings />,
  },
  {
    title: "Déconnexion",
    key: "logout",
    url: "/logout",
    icon: <AiOutlineLogout />,
  },
];
