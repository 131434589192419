import {
  Collapse,
  DatePicker,
  DatePickerProps,
  Drawer,
  Popconfirm,
  PopconfirmProps,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import * as S from "./OrderDrawer.styles";
import { FONT_SIZE, FONT_WEIGHT } from "../../../../styles/constants";
import {
  CalendarOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AiOutlineNumber } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderProduct,
  fetchOrderDetails,
  getCurrentOrders,
  getPendingOrders,
  getPlacedOrders,
} from "../../../../store/slices/ordersSlice";
import { RootState } from "../../../../store/store";
import { GrSend } from "react-icons/gr";
import { AiFillMessage } from "react-icons/ai";
import { BaseForm } from "../../../common/forms/BaseForm/BaseForm";
import { orderProductData } from "../../../../api/orders.api";

interface DrawerProps {
  id: number;
  numCommande: number;
  name: string;
  tele: string;
  adresse: string;
  ville: string;
  expire: string;
  statut: string;
  somme: string;
  date_estime: string;
  date_livre: string;
  onClose:
    | ((
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
      ) => void)
    | undefined;
  open: boolean | undefined;
}

export const OrderDrawer: React.FC<DrawerProps> = ({
  id,
  numCommande,
  name,
  adresse,
  ville,
  tele,
  somme,
  expire,
  statut,
  date_estime,
  date_livre,
  onClose,
  open,
}) => {
  const { Panel } = Collapse;
  const [notes, setNotes] = useState("");
  const [openPopConfirm, setOpenPopConfirm] = useState(false);
  const dispatch = useDispatch();
  const { loading, orderDetailsInfo } = useSelector(
    (state: RootState) => state.orders.orderDetails
  );

  const { produits, commande } = orderDetailsInfo;
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  const statutColorMap: { [statut: string]: object } = {
    "En attente de révision": { backgroundColor: "grey", color: "#f8f8f8" },
    "Attendant la livraison": { backgroundColor: "yellow", color: "#f8f8f8" },
    "En route": { backgroundColor: "purple", color: "#f8f8f8" },
    Payé: { backgroundColor: "green", color: "#f8f8f8" },
    Impayé: { backgroundColor: "red", color: "#f8f8f8" },
  };

  const handleDeleteBtn = (commande_id: number, product_id: number) => {
    const bodyData: orderProductData = {
      produit_id: product_id,
      commande_id: commande_id,
    };
    dispatch(deleteOrderProduct(bodyData));
  };

  const handleConfirmBtn = () => {};

  const confirm = (commande_id: number, product_id: number) => {
    handleDeleteBtn(commande_id, product_id);
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
  };

  return (
    <S.StyledDrawer
      title={
        <>
          <h2>{commande.restaurant}</h2>
          <span>N° de la commande: #{commande.numCommande}</span>
        </>
      }
      footer={
        <S.FooterForm>
          <BaseForm.Item
            name="date"
            label=""
            rules={[{ required: true, message: "Choisir une date" }]}
          >
            <S.DatePickerFooter onChange={onChange} />
          </BaseForm.Item>

          <S.ActionWrapper>
            <S.CancelButton onClick={onClose}>Annuler</S.CancelButton>
            <S.ConfirmButton htmlType="submit" onClick={handleConfirmBtn}>
              Confirmer
            </S.ConfirmButton>
          </S.ActionWrapper>
        </S.FooterForm>
      }
      size="large"
      onClose={onClose}
      open={open}
    >
      <Collapse expandIconPosition="end">
        <Panel
          style={{ fontSize: FONT_SIZE.lg, fontWeight: FONT_WEIGHT.semibold }}
          header="Articles commandés"
          key="1"
        >
          {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> */}
          <S.ProductsWrapper>
            {produits.map((p) => (
              <S.Product>
                <S.ProductTitle>{p.name}</S.ProductTitle>
                <S.ProductDescription>{p.category}</S.ProductDescription>
                <S.ProductQuantity>
                  <S.ProductBadge>{p.unity}</S.ProductBadge>
                  <p>x {p.quantity}</p>
                  <Popconfirm
                    title="Delete the task"
                    onConfirm={() => confirm(id, p.id)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <S.ProductButton
                      disabled={produits.length === 1}
                      // onClick={
                      //   /*() => handleDeleteBtn(id, p.id)*/
                      // }
                    >
                      <CloseOutlined />
                    </S.ProductButton>
                  </Popconfirm>
                </S.ProductQuantity>
              </S.Product>
            ))}
          </S.ProductsWrapper>
        </Panel>
      </Collapse>
      <Collapse expandIconPosition="end" style={{ marginTop: "2rem" }}>
        <Panel
          style={{ fontSize: FONT_SIZE.lg, fontWeight: FONT_WEIGHT.semibold }}
          header="Informations"
          key="1"
        >
          {/*-------------INFORMATIONS SUR LA COMMANDE------------------------------------*/}
          <S.InformationSection>
            <S.InformationSectionTitle>
              Informations sur la commande
            </S.InformationSectionTitle>
            {/* --------------------STATUT------------------ */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <Loading3QuartersOutlined />
                <p>Statut</p>
              </S.IconAndTitle>
              <S.InformationContent>
                <S.InfoStatut style={statutColorMap[statut]}>
                  <p>{statut}</p>
                </S.InfoStatut>
              </S.InformationContent>
            </S.InformationWrapper>
            {/* --------------------N COMMANDE------------------- */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <AiOutlineNumber />
                <p>N commande</p>
              </S.IconAndTitle>
              <S.InformationContent>
                {commande.numCommande}
              </S.InformationContent>
            </S.InformationWrapper>
            {/* --------------------DATE DE COMMANDE--------------- */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <CalendarOutlined />
                <p>Date de commande</p>
              </S.IconAndTitle>
              <S.InformationContent>
                {commande.date_souhaitee}
              </S.InformationContent>
            </S.InformationWrapper>
            {/* --------------------PLACE PAR --------------- */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <UserOutlined />
                <p>Placé par</p>
              </S.IconAndTitle>
              <S.InformationContent>{commande.name}</S.InformationContent>
            </S.InformationWrapper>
          </S.InformationSection>
          {/*-------------INFORMATIONS DE LIVRAISON-----------------------------------*/}
          <S.InformationSection>
            <S.InformationSectionTitle>
              Informations de livraison
            </S.InformationSectionTitle>
            {/* --------------------Addresse------------------ */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <GrSend />
                <p>Addresse</p>
              </S.IconAndTitle>
              <S.InformationContent>{commande.adresse}</S.InformationContent>
            </S.InformationWrapper>
            {/* --------------------Addresse------------------- */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <AiFillMessage />
                <p>Addresse</p>
              </S.IconAndTitle>
              <S.InformationContent>
                {commande.commentaire}
              </S.InformationContent>
            </S.InformationWrapper>
          </S.InformationSection>
          {/*-------------COORDONEES-----------------------------------*/}
          <S.InformationSection>
            <S.InformationSectionTitle>Coordonnées</S.InformationSectionTitle>
            {/* --------------------TELEPHONE------------------ */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <PhoneOutlined />
                <p>Téléphone</p>
              </S.IconAndTitle>
              <S.InformationContent>{commande.phone}</S.InformationContent>
            </S.InformationWrapper>
            {/* --------------------EMAIL------------------- */}
            <S.InformationWrapper>
              <S.IconAndTitle>
                <MailOutlined />
                <p>Emaill</p>
              </S.IconAndTitle>
              <S.InformationContent>{commande.email}</S.InformationContent>
            </S.InformationWrapper>
          </S.InformationSection>
        </Panel>
      </Collapse>
      {/* ---------------------Notes--------------------------- */}
      <S.NotesWrapper>
        <S.NoteLabel>Notes :</S.NoteLabel>
        <S.NoteInput
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
            console.log(notes);
          }}
        ></S.NoteInput>
      </S.NotesWrapper>
    </S.StyledDrawer>
  );
};
