import styled from "styled-components";
import { BaseCard as CommonCard } from "../../../common/BaseCard/BaseCard";

export const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
  margin-top: 2.5rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; // This ensures the Card takes the full height

  .ant-card-body {
    flex-grow: 1;
  }
`;
