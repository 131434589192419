import { useDispatch, useSelector } from "react-redux";
import * as S from "./OrderCard.styles";
import { RootState } from "../../../../store/store";
import { BaseSelect } from "../../../common/selects/BaseSelect/BaseSelect";
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  Menu,
  MenuProps,
  Select,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { BaseButton } from "../../../common/BaseButton/BaseButton";
import { BaseTypography } from "../../../common/BaseTypography/BaseTypography";
import { BASE_COLORS, FONT_SIZE } from "../../../../styles/constants";
import { orderData, orderStatutData } from "../../../../api/orders.api";
import {
  changeStatut,
  deleteOrder,
  fetchOrderDetails,
} from "../../../../store/slices/ordersSlice";
import { OrderDrawer } from "../Drawer/OrderDrawer";

interface SelectedItem {
  key: string;
  color?: any;
  label: string;
}
interface StatutInfo {
  color: string;
  key: string;
}

interface OrderCardProps {
  id: number;
  numCommande: number;
  name: string;
  tele: string;
  adresse: string;
  ville: string;
  expire: string;
  statut: string;
  somme: string;
  date_estime: string;
  date_livre: string;
}

export const OrderCard: React.FC<OrderCardProps> = ({
  id,
  numCommande,
  name,
  adresse,
  ville,
  tele,
  somme,
  expire,
  statut,
  date_estime,
  date_livre,
}) => {
  const statutMap: { [statut: string]: StatutInfo } = {
    "En attente de révision": { color: "grey", key: "1" },
    "Attendant la livraison": { color: "yellow", key: "2" },
    "En route": { color: "purple", key: "3" },
    Payé: { color: "green", key: "4" },
    Impayé: { color: "red", key: "5" },
  };
  const statutColorMap: { [statut: string]: string } = {
    "1": "grey",
    "2": "yellow",
    "3": "purple",
    "4": "green",
    "5": "red",
  };
  const defaultStatus = statutMap[statut].key;
  console.log(defaultStatus, statut, statutMap[statut].color);
  const [selectedValue, setSelectedValue] = useState<SelectedItem | null>({
    key: defaultStatus,
    label: statut,
    color: statutMap[statut].color,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { Panel } = Collapse;

  const showDrawer = () => {
    setOpen(true);
  };

  const handleShowButton = async (id: number) => {
    try {
      await dispatch(fetchOrderDetails(id));
      setOpen(true);
    } catch (error) {
      console.error("Error fetching order Details: ", error);
    }
  };

  const onClose = () => {
    setOpen(false);
  };
  const deleteConfirmRef = useRef<HTMLDivElement>(null);

  const handleDeleteFunctionality = async (id: number) => {
    try {
      const data: orderData = {
        id: id,
      };
      const response = await dispatch(deleteOrder(data));
      return response;
    } catch (error) {
      console.error("Error deleting order: ", error);
    }
  };
  const statutTextMap: { [statutKey: string]: string } = {
    "1": "En attente de révision",
    "2": "Attendant la livraison",
    "3": "En route",
    "4": "Payé",
    "5": "Impayé",
  };

  const handleChangeStatut = async (id: number, statutKey: string) => {
    console.log(
      "handleChangeStatut called with id:",
      id,
      "and statutKey:",
      statutKey
    );
    const statut = statutTextMap[statutKey];
    try {
      const data: orderStatutData = {
        id: id,
        statut: statut,
      };
      const response = await dispatch(changeStatut(data));
      console.log("Response from dispatch:", response);
      const key = statutMap[response.payload.commande.statut].key;
      console.log("Updated key:", key);
      setSelectedValue({
        key: key,
        label: statutTextMap[key],
        color: statutColorMap[key],
      });
      console.log("Selected value:", selectedValue);
      return response;
    } catch (error) {
      console.error("Error changing statut order: ", error);
    }
  };

  const generateTextByStatut = (statut: string) => {
    let textDate: string;
    let date: string;
    switch (statut) {
      case "En attente de révision":
        textDate = "Expire le";
        date = expire;
        break;
      case "Attendant la livraison":
      case "En route":
        textDate = "Date estimée de livraison";
        date = date_estime;
        break;
      case "Payé":
      case "Impayé":
        textDate = "Date de livraison";
        date = date_livre;
        break;
      default:
        textDate = "Expire le";
        date = expire;
        break;
    }
    return { textDate, date };
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteConfirmRef.current &&
        !deleteConfirmRef.current.contains(event.target as Node)
      ) {
        setShowDeleteConfirm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedValue]);
  const handleDelete = async () => {
    if (showDeleteConfirm) {
      await handleDeleteFunctionality(id);
      setShowDeleteConfirm(false);
    } else {
      setClicked(true);
      setShowDeleteConfirm(true);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  const handleMenuClick = (e: any) => {
    console.log(e);
  };
  const onClick: MenuProps["onClick"] = (e) => {
    console.log(e.key);
    handleChangeStatut(id, e.key);
    // setSelectedValue({
    //   key: key,
    //   label: statutTextMap[key],
    //   color: statutColorMap[key],
    // });
  };
  const items: MenuProps["items"] = [
    {
      label: "En attente de révision",
      key: "1",
      icon: <S.Circle color="grey" />,
    },
    {
      label: "Attendant la livraison",
      key: "2",
      icon: <S.Circle color="yellow" />,
    },
    {
      label: "En route",
      key: "3",
      icon: <S.Circle color="purple" />,
    },
    {
      label: "Payé",
      key: "4",
      icon: <S.Circle color="green" />,
    },
    {
      label: "Impayé",
      key: "5",
      icon: <S.Circle color="red" />,
    },
  ];
  /* const menu = (
    <Menu onClick={handleMenuClick}>
      <CustomMenuItem
        item={{ key: "1", label: "En attente de révision", color: "grey" }}
      />
      <CustomMenuItem
        item={{ key: "2", label: "Attendant la livraison", color: "yellow" }}
      />
      <CustomMenuItem item={{ key: "3", label: "En route", color: "purple" }} />
      <CustomMenuItem item={{ key: "4", label: "Payé", color: "green" }} />
      <CustomMenuItem item={{ key: "5", label: "Impayé", color: "red" }} />
    </Menu>
  ); */
  return (
    <>
      <S.OrderCard
        ref={deleteConfirmRef}
        title={
          <S.SelectWrapper
            style={{
              height: "2.5rem",
              position: "relative",
              overflow: "visible",
            }}
          >
            <S.StatusSelect
              defaultValue={selectedValue}
              onChange={(e: any) => {
                handleChangeStatut(id, e);
              }}
              value={selectedValue?.key}
            >
              {items.map((item: any) => (
                <Select.Option key={item.key}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 8 }}>{item.icon}</div>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.label}
                    </div>
                  </div>
                </Select.Option>
              ))}
            </S.StatusSelect>
          </S.SelectWrapper>
        }
        actions={[
          <S.ActionRow justify={"space-between"}>
            <S.DeleteButton
              style={{ width: showDeleteConfirm ? "100%" : "" }}
              onClick={handleDelete}
            >
              {showDeleteConfirm ? (
                <>
                  <S.DeleteIcon />
                  Supprimer ?
                </>
              ) : (
                <DeleteFilled style={{ color: "var(--heading-color)" }} />
              )}
            </S.DeleteButton>
            {showDeleteConfirm ? null : (
              <S.DisplayButton onClick={() => handleShowButton(id)}>
                Afficher
              </S.DisplayButton>
            )}
          </S.ActionRow>,
        ]}
      >
        <div style={{ marginTop: "-1rem" }}>
          <BaseTypography
            style={{
              color: "var(--text-light-color)",
              fontSize: FONT_SIZE.xxxs,
            }}
          >
            N° de la commande: #{numCommande}
          </BaseTypography>
          <Typography
            style={{ fontSize: FONT_SIZE.xxl, color: BASE_COLORS.lightGreen }}
          >
            {name}
          </Typography>
        </div>
        <div>
          <BaseTypography>{adresse}</BaseTypography>
          <BaseTypography>{ville}</BaseTypography>
          <BaseTypography>
            Tel: <strong>{tele}</strong>
          </BaseTypography>
        </div>
        <div style={{ marginTop: "0.5rem", borderTop: "2px solid #393939" }}>
          <BaseRow
            style={{ alignItems: "baseline", marginTop: "0.5rem" }}
            justify={"space-between"}
          >
            <BaseTypography
              style={{
                color: "var(--text-light-color)",
                fontSize: FONT_SIZE.xxxs,
              }}
            >
              Sous-total:{" "}
            </BaseTypography>
            <BaseTypography>{somme} Dhs</BaseTypography>
          </BaseRow>
          <BaseRow style={{ alignItems: "baseline" }} justify={"space-between"}>
            <BaseTypography
              style={{
                color: "var(--text-light-color)",
                fontSize: FONT_SIZE.xxxs,
              }}
            >
              {generateTextByStatut(statut).textDate} :
            </BaseTypography>
            <BaseTypography>{generateTextByStatut(statut).date}</BaseTypography>
          </BaseRow>
        </div>
      </S.OrderCard>
      <OrderDrawer
        id={id}
        adresse={adresse}
        date_estime={date_estime}
        date_livre={date_livre}
        expire={expire}
        name={name}
        numCommande={numCommande}
        somme={somme}
        statut={statut}
        tele={tele}
        ville={ville}
        key={id}
        onClose={onClose}
        open={open}
      ></OrderDrawer>
    </>
  );
};

const CustomMenuItem: React.FC<{ item: any }> = ({ item }) => {
  const colorMap: { [key: string]: string } = {
    "1": "grey",
    "2": "yellow",
    "3": "purple",
    "4": "green",
    "5": "red",
  };

  const color = colorMap[item.key];

  return (
    <Menu.Item style={{ backgroundColor: "transparent" }} key={item.key}>
      <S.Circle color={item.color} />
      {item.label}
    </Menu.Item>
  );
};
