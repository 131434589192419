import { Button, Card } from "antd";
import styled from "styled-components";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../../styles/constants";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";

export const CardContent = styled(Card)`
  width: 100%;
  position: relative;
  border: none;
  padding: 1rem;
  background-color: var(--card-background-color);
  .ant-card-head {
    border: none;
  }
  .ant-card-actions {
    background-color: transparent;
    border: none;
    margin-top: -1rem;
  }
`;

export const Title = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxxl};
`;

export const Description = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
`;

export const EditButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const StyledForm = styled(BaseForm)`
  & .ant-form-item-label > label {
    color: #1b936e;
  }
`;
