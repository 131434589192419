import styled from "styled-components";
import { media } from "../../../../styles/constants";
import { BaseLayout } from "../../../common/BaseLayout/BaseLayout";

export const LayoutMaster = styled(BaseLayout)`
  max-height: 100vh;
  overflow: hidden;
`;

export const LayoutMain = styled(BaseLayout)`
  max-height: 100vh;
  overflow: hidden;
  @media only screen and ${media.md} {
    margin-left: 80px;
  }

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;
