import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./Inventory.styles";
import { useEffect, useState } from "react";
import { Alert, Skeleton } from "antd";
import { Card } from "./Card/Card";
import add from "../../assets/icons/add.png";
import { AddModal } from "./AddModal/AddModal";
import { BASE_COLORS } from "../../styles/constants";
import { BaseRow } from "../common/BaseRow/BaseRow";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { EditableTable } from "../table/editableTable/EditableTable";
import { unitOptions } from "../../constants/config/units";
import { categoryOptions } from "../../constants/config/category";
import { RootState } from "../../store/store";

export const Inventory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    ingredients: { ruptureStock, stockFaible, totalArticle },
    loading,
  } = useSelector((state: RootState) => state.ingredients);
  const ingredientsColumns = [
    {
      title: t("inventory.ingredientsColumns.image"),
      dataIndex: "image",
      width: "5%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <img
            src={
              text === "image"
                ? "assets/default-ingredients.jpg"
                : process.env.REACT_APP_IMAGE_URL + "produits_images/" + text
            }
            srcSet="ingredient image"
            style={{ width: "2.5rem", height: "auto", borderRadius: "5px" }}
          />
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.name"),
      dataIndex: "name",
      width: "15%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.price"),
      dataIndex: "prix",
      width: "15%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.unit"),
      dataIndex: "unity",
      width: "10%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.stock"),
      dataIndex: "quantity",
      width: "10%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(text, 10) === 0;
        const isLowQuantity = parseInt(text, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {isZeroQuantity ? "RUPTURE DE STOCK" : text}
          </span>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.moq"),
      dataIndex: "moq",
      width: "10%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(text, 10);
        return (
          <BaseRow justify={"space-between"}>
            <span
              style={{
                marginTop: isLowQuantity ? "0.6rem" : 0,
                color: isZeroQuantity
                  ? BASE_COLORS.red
                  : isLowQuantity
                  ? BASE_COLORS.yellow
                  : "inherit",
              }}
            >
              {text}
            </span>
            {isLowQuantity && !isZeroQuantity && (
              <Alert
                style={{
                  marginLeft: "5px",
                  display: "inline-block",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                message={
                  <ExclamationCircleFilled
                    style={{ fontSize: "1.5rem", color: BASE_COLORS.yellow }}
                  />
                }
                type="warning"
              />
            )}
          </BaseRow>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.limit"),
      dataIndex: "limit",
      width: "10%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(text, 10);
        return (
          <BaseRow justify={"space-between"}>
            <span
              style={{
                marginTop: isLowQuantity ? "0.6rem" : 0,
                color: isZeroQuantity
                  ? BASE_COLORS.red
                  : isLowQuantity
                  ? BASE_COLORS.yellow
                  : "inherit",
              }}
            >
              {text}
            </span>
            {isLowQuantity && !isZeroQuantity && (
              <Alert
                style={{
                  marginLeft: "5px",
                  display: "inline-block",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                message={
                  <ExclamationCircleFilled
                    style={{ fontSize: "1.5rem", color: BASE_COLORS.yellow }}
                  />
                }
                type="warning"
              />
            )}
          </BaseRow>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.category"),
      dataIndex: "category",
      width: "10%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("inventory.ingredientsColumns.notes"),
      dataIndex: "note",
      width: "20%",
      editable: true,
      render: (text: string, record: any) => {
        const isZeroQuantity = parseInt(record.quantity, 10) === 0;
        const isLowQuantity =
          parseInt(record.quantity, 10) <= parseInt(record.limite, 10);
        return (
          <span
            style={{
              color: isZeroQuantity
                ? BASE_COLORS.red
                : isLowQuantity
                ? BASE_COLORS.yellow
                : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
  ];

  const onAddIngredient = () => {
    setOpen(true);
  };

  const hideAddModal = () => {
    setOpen(false);
  };

  const handleAddSuccess = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <S.Main>
      <S.TitlePrimary>{t("common.menu.inventory")}</S.TitlePrimary>
      <S.ListWrapper>
        <Card
          color="#2D2D2D"
          numberResult={totalArticle}
          title="Total des articles"
          textColor="#F8F8F8"
        ></Card>
        <Card
          color="#D2D486"
          numberResult={stockFaible}
          title="Articles en stock faible"
          textColor="#2D2D2D"
        ></Card>
        <Card
          color="#FF6060"
          numberResult={ruptureStock}
          title="Articles en rupture de stock"
          textColor="#F8F8F8"
        ></Card>
        <S.AddButton>
          <S.AddImage
            src={add}
            preview={false}
            alt="Button Image"
            onClick={() => onAddIngredient()}
          ></S.AddImage>
        </S.AddButton>
      </S.ListWrapper>
      <S.TablesWrapper>
        <EditableTable
          columns={ingredientsColumns}
          unitOptions={unitOptions}
          categoryOptions={categoryOptions}
        />
      </S.TablesWrapper>

      <AddModal
        onAddSuccess={handleAddSuccess}
        open={open}
        onCancel={hideAddModal}
      />
    </S.Main>
  );
};
