import styled from "styled-components";
import { FONT_FAMILY, FONT_SIZE } from "../../../styles/constants";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { BaseRow } from "../../common/BaseRow/BaseRow";

export const Main = styled(BaseRow)`
  display: flex;
  margin: 1rem;
  height: 100%;
  font-family: ${FONT_FAMILY.main};
`;

export const TitlePrimary = styled(BaseTypography)`
  width: 100%;
  font-size: ${FONT_SIZE.xxxxl};
`;

export const TitleSecondary = styled(BaseTypography)`
  width: 100%;
  font-size: ${FONT_SIZE.xl};
`;

export const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const NoDataFound = styled.div`
  width: 100%;
  height: 16.5rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
`;
