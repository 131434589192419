import { FONT_SIZE } from "../../../styles/constants";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "./MyRestaurantsPage.styles";
import { OrderCard } from "../../orders/components/OrderCard/OrderCard";
import { RestaurantCard } from "../components/MyrestaurantCard/RestaurantCard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllRestaurants,
  getConnectedRestaurants,
  getInvites,
} from "../../../store/slices/restaurantsSlice";
import { RootState } from "../../../store/store";
import { Skeleton } from "antd";
import {
  ConnectedRestaurant,
  Demande,
  Restaurant,
} from "../../../domain/restaurantModel";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { fetchPackages } from "../../../store/slices/packagesSlice";

export const MyRestaurants = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    invites: { demandes },
    connectedRestaurants: { magasins },
    loading,
  } = useSelector((state: RootState) => state.restaurants);

  const isLoading: boolean =
    loading !== "idle" && loading !== "succeeded" && loading !== "failed";

  useEffect(() => {
    dispatch(getInvites());
    dispatch(getConnectedRestaurants());
    dispatch(fetchPackages());
  }, []);

  return (
    <S.Main>
      <S.TitlePrimary>{t("common.menu.restaurants")}</S.TitlePrimary>
      <S.TitleSecondary>{t("restaurants.connexion")}</S.TitleSecondary>
      <S.ListWrapper>
        {isLoading && (
          <Skeleton
            style={{ height: "16rem", width: "15rem" }}
            avatar
            active
            loading={true}
          />
        )}
        {!isLoading && demandes.length === 0 ? (
          <S.NoDataFound>
            <ExclamationCircleFilled style={{ marginRight: "5px" }} />
            Aucune demandes pour l'instant
          </S.NoDataFound>
        ) : (
          !isLoading &&
          demandes?.map((demande: Demande) => (
            <RestaurantCard
              key={demande.id}
              id={demande.id}
              name={demande.name}
              address={demande.adresse}
              isConnected={false}
              deliveryDate={demande.dateDemande}
              owner={demande.nom}
              phoneNumber={demande.tel}
            ></RestaurantCard>
          ))
        )}
      </S.ListWrapper>
      <S.TitleSecondary>{t("restaurants.connected")}</S.TitleSecondary>
      <S.ListWrapper>
        {isLoading && (
          <Skeleton
            style={{ height: "16rem", width: "15rem" }}
            avatar
            active
            loading={true}
          />
        )}
        {!isLoading && magasins.length === 0 ? (
          <S.NoDataFound>
            <ExclamationCircleFilled style={{ marginRight: "5px" }} />
            Aucun restaurant connectés pour l'instant
          </S.NoDataFound>
        ) : (
          !isLoading &&
          magasins?.map((demande: ConnectedRestaurant) => (
            <RestaurantCard
              key={demande.id}
              id={demande.id}
              name={demande.name}
              address={demande.adresse}
              isConnected={true}
              deliveryDate={demande.dateConnexion}
              owner={demande.nom}
              phoneNumber={demande.tel}
            ></RestaurantCard>
          ))
        )}
      </S.ListWrapper>
    </S.Main>
  );
};
