import styled, { css } from "styled-components";
type SenderStyles = {
  [key: string]: ReturnType<typeof css>;
};

const senderStyles: SenderStyles = {
  magasin: css`
    background-color: var(--layout-header-bg-color);
    align-self: flex-start;
  `,
  fournisseur: css`
    background-color: #3db389;
    align-self: flex-end;
  `,
};

export const ConversationWrapper = styled.div`
  overflow: auto;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  gap: 1rem;
`;

export const Message = styled.div<{ sender: keyof SenderStyles }>`
  border-radius: 7px;
  width: 50%;
  text-align: start;
  padding: 0.5rem;

  ${(props) => senderStyles[props.sender]}
`;

export const Main = styled.div`
  height: calc(100% - 3rem);
  width: 100%;
  display: flex;
  flex-direction: column;
`;
