import styled from "styled-components";
import { BaseTable } from "../../../../common/BaseTable/BaseTable";

export const TableView = styled(BaseTable)`
  &
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > .ant-table-cell {
    background-color: var(--layout-header-bg-color);
    color: var(--text-main-color);
    border-right: none;
  }
`;
