import { Button, Form, Input } from "antd";
import { BsSendFill } from "react-icons/bs";
import * as S from "./ConversationForm.styles";
import { MessageData } from "../../../api/conversations.api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectedConversation,
  sendMessage,
} from "../../../store/slices/conversationsSlice";
import { RootState } from "../../../store/store";

interface Props {
  magasin_id: number;
}

export const ConversationForm: React.FC<Props> = ({ magasin_id }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectedConversation: number = useSelector(
    (state: RootState) => state.conversations.selectedConversation
  );
  const onFinish = (values: any) => {
    console.log(values);
    const requestBody: MessageData = {
      magasin_id: magasin_id,
      body: values.message,
    };
    dispatch(sendMessage({ message: requestBody, conversationId: 1 }));

    dispatch(fetchSelectedConversation(selectedConversation));
    form.resetFields();
  };

  return (
    <S.StyledForm layout="inline" form={form} onFinish={onFinish}>
      <Form.Item name="message" style={{ flex: 1 }}>
        <S.MessageInput type="text" placeholder="Entrez votre message" />
      </Form.Item>
      <Button
        style={{ height: "2.5rem" }}
        type="primary"
        htmlType="submit"
        icon={<BsSendFill />}
      ></Button>
    </S.StyledForm>
  );
};
