import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteToken,
  deleteUser,
  persistToken,
  readToken,
} from "../../services/localStorage.service";
import { AuthData, login } from "../../api/auth.api";
import { setUser } from "./userSlice";
import { StatistiqueModel } from "../../domain/statistiqueModel";
import { DashboardData, fetchStatistiquesApi } from "../../api/dashboard.api";

export interface ProduitPopulaire {
  commande: number;
  name: string;
}

interface DashboardSlice {
  pourcentage?: number | null;
  revenu: number;
  commandesPayées: number;
  commandesImpayées: number;
  produitPopulaire: ProduitPopulaire[];
  commandesCompletees: { [key: string]: number };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: DashboardSlice = {
  pourcentage: 0,
  revenu: 0,
  commandesPayées: 0,
  commandesImpayées: 0,
  produitPopulaire: [],
  commandesCompletees: {},
  loading: "idle",
};

export const getStatistiques: any = createAsyncThunk(
  "dashboard/getStatistiques",
  async (data: DashboardData) => {
    const statistiques: StatistiqueModel = await fetchStatistiquesApi(
      "/statistique",
      data
    );
    return statistiques;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatistiques.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getStatistiques.fulfilled, (state, action) => {
        const { pourcentage, ...rest } = action.payload;
        state.pourcentage = pourcentage;
        state.revenu = rest.revenu;
        state.commandesPayées = rest.commandesPayées;
        state.commandesImpayées = rest.commandesImpayées;
        state.produitPopulaire = rest.produitPopulaire;
        state.commandesCompletees = rest.commandesCompletees;
        state.loading = "succeeded";
      })
      .addCase(getStatistiques.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default dashboardSlice.reducer;
