import styled from "styled-components";
import { Card } from "antd";
import { BaseImage } from "../../../common/BaseImage/BaseImage";
import { BaseTypography } from "../../../common/BaseTypography/BaseTypography";
import {
  FONT_SIZE,
  media,
  FONT_WEIGHT,
  BASE_COLORS,
} from "../../../../styles/constants";
import { BaseButton } from "../../../common/BaseButton/BaseButton";

export const SummaryCard = styled(Card)`
  width: 100%;
  height: 13rem;
  position: relative;
  border: none;
  background-color: var(--card-background-color);

  & .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--icon-background-color);
`;

export const CardImage = styled(BaseImage)`
  width: 2rem;
  height: auto;
`;

export const CardBody = styled.div``;

export const CardTitle = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  color: var(--text-main-color);
  text-align: left;
`;

export const CardNumber = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxxxl};
  color: var(--text-main-color);
  text-align: left;
`;

export const CardButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.xxs};
  height: 1rem;
  border: none;
  margin-top: 1rem;
  background-color: #fcff77;
  color: #111315;

  &:hover {
    background-color: #fcff77;
    color: #111315;
  }
  &:focus {
    background-color: #fcff77;
    color: #111315;
  }
`;
