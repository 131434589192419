import { Priority } from "../constants/enums/priorities";
import { Ingredient } from "./ingredients.api";

export interface Tag {
  value: string;
  priority: Priority;
}

export interface BasicTableRow {
  key: number;
  name: string;
  age: number;
  address: string;
  tags?: Tag[];
}

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface BasicTableData {
  data: Ingredient[];
  // pagination: Pagination;
}

export interface EditableTableData extends BasicTableData {
  data: Ingredient[];
}

export const getEditableTableData = (
  data: Ingredient[]
): Promise<EditableTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      const outOfStock = data.filter((item) => item.quantity === 0);
      const inLimitOrLess = data.filter(
        (item) => item.quantity > 0 && item.quantity <= item.limit
      );
      const rest = data.filter((item) => item.quantity > item.limit);

      const sortedData = [...outOfStock, ...inLimitOrLess, ...rest];

      res({
        data: sortedData,
      });
    }, 1000);
  });
};
