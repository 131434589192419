import {
  Checkbox,
  CheckboxProps,
  FormProps,
  Input,
  Select,
  TimePicker,
} from "antd";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import * as S from "./DeliverySettings.styles";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { useEffect, useState } from "react";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { updateDetails } from "../../../store/slices/settingsSlice";
import cities from "../../../utils/villes.json";
import moment from "moment";
import dayjs from "dayjs";

export const DeliverySettings: React.FC = () => {
  const [form] = BaseForm.useForm();
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [checked, setChecked] = useState(true);
  const { settingsInfo } = useSelector((state: RootState) => state.settings);
  const daysOptions = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" },
  ];

  const categoryOptions = [
    { value: "Viande", label: "Viande" },
    { value: "Poulet", label: "Poulet" },
    { value: "Boissons", label: "Boissons" },
    { value: "Fruits et légumes", label: "Fruits et légumes" },
  ];

  const selectedDays = settingsInfo?.details_fournisseur.jours_livraison
    .split(",")
    .map((day: any) => day.trim());
  const selectedCategories = settingsInfo?.details_fournisseur.categorie
    .split(",")
    .map((day: any) => day.trim());
  const selectedCities = settingsInfo?.details_fournisseur.zone_livraison
    .split(",")
    .map((zone: any) => zone.trim());
  const [checkedCities, setCheckedCities] =
    useState<CheckboxValueType[]>(selectedCities);
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(selectedDays);

  useEffect(() => {
    setCheckedList(selectedDays);
  }, [settingsInfo]);

  useEffect(() => {
    form.setFieldsValue({
      jours_livraison: checkedList,
      zone_livraison: checkedCities,
      categorie: selectedCategories,
    });
  }, [checkedList, checkedCities, selectedCategories]);

  const handleForm: FormProps["onFinish"] = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if (key === "heure" || key === "time_end") {
          const date = new Date(values[key]);
          const hour = date.getHours();
          const minutes = date.getMinutes();
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
          values[key] = `${hour}:${formattedMinutes}`;
        }
        formData.append(key, values[key]);
      }
    });
    dispatch(updateDetails(formData));
    setIsEditable((isEditable) => !isEditable);
  };

  const handleEditBtn = () => {
    setIsEditable((isEditable) => !isEditable);
  };

  const handleCancelBtn = () => {
    setIsEditable((isEditable) => !isEditable);
  };
  return (
    <S.CardContent>
      <S.Title>Paramètres de la livraison</S.Title>
      <S.Description>
        Mettez à jour votre paramètres globales de la livraison
      </S.Description>
      <S.EditButton
        style={isEditable ? { display: "none" } : undefined}
        size="small"
        onClick={handleEditBtn}
        type="primary"
      >
        Modifier
      </S.EditButton>
      <BaseRow style={{ width: "100%" }} justify={"space-between"}>
        <S.StyledForm
          layout="vertical"
          form={form}
          onFinish={handleForm}
          style={{ width: "100%", marginTop: "1rem" }}
          initialValues={{
            jours_livraison: selectedDays,
            zone_livraison: selectedCities,
            categorie: selectedCategories,
          }}
        >
          <BaseRow
            style={{ width: "100%" }}
            gutter={16}
            justify={"space-between"}
          >
            <BaseCol xs={24} md={12}>
              <BaseForm.Item
                label="Frais de la livraison"
                name="frais_livraison"
              >
                <Input
                  type="number"
                  placeholder={
                    settingsInfo?.details_fournisseur.frais_livraison
                  }
                  disabled={!isEditable}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseForm.Item label="Montant minimum" name="montant_minimum">
                <Input
                  type="number"
                  placeholder={
                    settingsInfo?.details_fournisseur.montant_minimum
                  }
                  disabled={!isEditable}
                ></Input>
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseForm.Item label="Début Heure de livraion" name="heure">
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={settingsInfo?.details_fournisseur.heure}
                  disabled={!isEditable}
                  format="HH:mm"
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseForm.Item label="Fin Heure de livraion" name="time_end">
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={settingsInfo?.details_fournisseur.time_end}
                  disabled={!isEditable}
                  format="HH:mm"
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow
            style={{ width: "100%" }}
            gutter={16}
            justify={"space-between"}
          >
            <BaseCol span={24}>
              <BaseForm.Item label="Zone de livraison" name="zone_livraison">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder={checkedCities}
                  options={cities}
                  disabled={!isEditable}
                  // onChange={(values) => {
                  //   form.setFieldsValue({
                  //     zone_livraison: values.join("; "),
                  //   });
                  // }}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseForm.Item label="Jours de livraison" name="jours_livraison">
                {/* <Checkbox
                  // style={{ display: "none" }}
                  indeterminate={indeterminate}
                  disabled={!isEditable}
                >
                  Check all
                </Checkbox> */}
                <S.StyledCheckBox
                  options={daysOptions}
                  disabled={!isEditable}
                  value={checkedList}
                  onChange={(values) => {
                    setCheckedList(values);
                    form.setFieldsValue({ jours_livraison: values.join(", ") });
                  }}
                />
              </BaseForm.Item>
            </BaseCol>
            <BaseCol xs={24} md={12}>
              <BaseForm.Item label="Catégories" name="categorie">
                <S.StyledCheckBox
                  options={categoryOptions}
                  disabled={!isEditable}
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
          <BaseRow
            style={{
              width: "100%",
              display: isEditable ? "flex" : "none",
            }}
            justify={"end"}
          >
            <BaseButton size="small" htmlType="submit" type="primary">
              Confirmer
            </BaseButton>
            <BaseButton
              size="small"
              onClick={handleCancelBtn}
              style={{ marginLeft: "1rem" }}
              type="primary"
              danger
            >
              Annuler
            </BaseButton>
          </BaseRow>
        </S.StyledForm>
      </BaseRow>
    </S.CardContent>
  );
};
