import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import * as S from "./SiderMenu.styles";
import { sidebarNavigation, SidebarNavigationItem } from "../sidebarNavigation";

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current
    ),
  []
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const logoutItem = sidebarNavigation.find((nav) => nav.key === "logout");

  const currentMenuItem = sidebarNavFlat.find(
    ({ url }) => url === location.pathname
  );
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname)
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <>
      <S.Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        onClick={() => setCollapsed(true)}
        items={sidebarNavigation
          .filter((nav) => nav.key !== "logout")
          .map((nav) => {
            const isSubMenu = nav.children?.length;

            return {
              key: nav.key,
              title: t(nav.title),
              label: isSubMenu ? (
                t(nav.title)
              ) : (
                <Link to={nav.url || ""}>{t(nav.title)}</Link>
              ),
              icon: nav.icon,
            };
          })}
      />
      {logoutItem && (
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          onClick={() => setCollapsed(true)}
        >
          <S.Menu.Item
            style={{ marginBottom: "1rem" }}
            key={logoutItem.key}
            icon={logoutItem.icon}
          >
            <Link to={logoutItem.url || ""}>{t(logoutItem.title)}</Link>
          </S.Menu.Item>
        </S.Menu>
      )}
    </>
  );
};

export default SiderMenu;
