import { Modal } from "antd";
import { title } from "process";
import * as S from "./Notifications.styles";
import profileImage from "../../../../assets/images/user-profile.jpg";

interface ModalProps {
  onOk: any;
  onCancel: any;
  open: any;
}

export const NotificationsModal: React.FC<ModalProps> = ({
  onOk,
  onCancel,
  open,
}) => {
  return (
    <S.StyledModal
      title="Notifications"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer=""
      style={{
        top: 50,
      }}
      mask={false}
    >
      <S.NotificationWrapper>
        <S.Avatar src={profileImage} alt="image prfile" />
        <S.Notification>
          <S.NotificationTitle>
            Y Café Restaurant vous a envoyé une demande de connexion
          </S.NotificationTitle>
          <S.NotificationDate>Mardi 23 Avril, 11.13</S.NotificationDate>
        </S.Notification>
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.Avatar src={profileImage} alt="image prfile" />
        <S.Notification>
          <S.NotificationTitle>
            Y Café Restaurant vous a envoyé une demande de connexion
          </S.NotificationTitle>
          <S.NotificationDate>Mardi 23 Avril, 11.13</S.NotificationDate>
        </S.Notification>
      </S.NotificationWrapper>

      <S.NotificationWrapper>
        <S.Avatar src={profileImage} alt="image prfile" />
        <S.Notification>
          <S.NotificationTitle>
            Y Café Restaurant vous a envoyé une demande de connexion
          </S.NotificationTitle>
          <S.NotificationDate>Mardi 23 Avril, 11.13</S.NotificationDate>
        </S.Notification>
      </S.NotificationWrapper>
    </S.StyledModal>
  );
};
