import styled from "styled-components";
import { BaseRow } from "../common/BaseRow/BaseRow";
import { BORDER_RADIUS, FONT_FAMILY, FONT_SIZE } from "../../styles/constants";
import { BaseTypography } from "../common/BaseTypography/BaseTypography";
import { BaseButton } from "../common/BaseButton/BaseButton";
import { BaseImage } from "../common/BaseImage/BaseImage";

export const Main = styled(BaseRow)`
  margin: 1rem;
  height: 100%;
  font-family: ${FONT_FAMILY.main};
`;

export const TitlePrimary = styled(BaseTypography)`
  width: 100%;
  font-size: ${FONT_SIZE.xxxxl};
`;

export const TitleSecondary = styled(BaseTypography)`
  width: 100%;
  font-size: ${FONT_SIZE.xl};
`;

export const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: start;
  align-items: end;
`;

export const AddButton = styled(BaseButton)`
  margin-left: auto;
`;

export const AddImage = styled(BaseImage)`
  width: 2rem;
  height: auto;
`;

export const TablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-thead
    > tr
    > .ant-table-cell {
    background-color: var(--layout-header-bg-color);
    color: var(--text-main-color);
    border-right: none;
  }
  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr
    > .ant-table-cell {
    background-color: var(--layout-header-bg-color);
    color: var(--text-main-color);
    border-right: none;
  }
  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    .ant-table-row-selected
    td {
    background-color: grey;
  }

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    .ant-table-row:nth-child(2n)
    td {
    background-color: var(--card-background-color);
  }

  &
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table {
    border-right: 1px solid;
  }
`;
