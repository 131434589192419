import styled from "styled-components";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { media } from "../../../styles/constants";

export const StyledRow = styled(BaseRow)`
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    justify-content: end;
  }
`;
