import styled from "styled-components";
import {
  FONT_SIZE,
  media,
  FONT_WEIGHT,
  BASE_COLORS,
} from "../../../styles/constants";
import { BaseAvatar } from "../../common/BaseAvatar/BaseAvatar";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BaseImage } from "../../common/BaseImage/BaseImage";
import { BaseButton } from "../../common/BaseButton/BaseButton";
import { Form } from "antd";

export const LoginWrapper = styled(BaseCol)`
  background-color: var(--background-color);
  height: 100vh;
  min-width: 100%;
  align-items: center;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;

export const RememberMeText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const ForgotPasswordText = styled.span`
  color: #5cae4a;
  font-size: ${FONT_SIZE.md};
  text-decoration: none;
`;

export const User = styled(BaseAvatar)`
  height: 80px;
  width: 80px;
  margin-bottom: 1vh;
  text-align: center;
`;

export const LoginForm = styled(Form)`
  width: 20rem;
`;

export const LogoImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-left: 10px;
  max-width: 150px; /* Adjust the width as needed */
`;

export const LogoImage = styled(BaseImage)`
  max-width: 150px; /* Adjust the width as needed */

  height: auto;
  margin-bottom: 40px; /* Add margin to separate the image and text */
`;

export const LoginMobile = styled.div`
  background-color: #111315;
  align-items: center;
  padding: 20px;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const SpanLinedWrapper = styled.div`
  width: 20rem;
`;

export const SpanLined = styled.span`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  font-size: ${FONT_SIZE.md};
  color: var(--text-light-color);

  &::before {
    content: "";
    height: 2px;
    display: block;
    background-color: #323232;
  }
  &::after {
    content: "";
    height: 2px;
    display: block;
    background-color: #323232;
  }
`;

export const DownloadButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  height: 2.7rem;
  background-color: var(--input-background-color);
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px solid #323232;
  width: 9rem;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  width: 20rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

export const DownloadImage = styled(BaseImage)`
  width: 1.5rem;
  height: auto;
`;

export const DownloadTextWrapper = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const DownloadText = styled.div`
  font-size: ${FONT_SIZE.xxs};
`;

export const DownloadSpan = styled.span`
  font-size: ${FONT_SIZE.xs};
`;

export const CircleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 10px;
  marging-left: 10px;
  max-width: 3000px; /* Adjust the width as needed */
  z-index: 1;
`;

export const Circle1 = styled(BaseImage)`
  width: 100%;
  height: auto;
`;

export const Circle2 = styled(BaseImage)``;
