import React from "react";
import * as S from "./MainSider/MainSider.styles";
import { RightOutlined } from "@ant-design/icons";
import logo from "../../../../assets/logo.png";
import logoLight from "../../../../assets/logoLight.png";
import pos_logo from "../../../../assets/images/FAVICON.png";
// import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useResponsive } from "../../../../hooks/useResponsive";
import { useAppSelector } from "../../../../hooks/reduxHooks";

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
  posSider?: boolean;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({
  isSiderCollapsed,
  toggleSider,
  posSider,
}) => {
  const { tabletOnly, mobileOnly } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === "dark" ? logo : logoLight;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img
          src={img}
          alt="Lightence"
          style={{ width: "120px", height: "auto", marginLeft: "30px" }}
        />
      </S.SiderLogoLink>
      {tabletOnly && (
        <>
          <S.CollapseButton
            shape="circle"
            size="small"
            $isCollapsed={isSiderCollapsed}
            icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
            onClick={toggleSider}
          />
        </>
      )}
    </S.SiderLogoDiv>
  );
};
