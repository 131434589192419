import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { Inventory } from "../../components/inventory/Inventory";
import { Settings } from "../../components/settings/Settings";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { RootState } from "../../store/store";
import { fetchSettings } from "../../store/slices/settingsSlice";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("common.menu.settings")}</PageTitle>
      <Settings />
    </>
  );
};

export default SettingsPage;
