export const BORDER_RADIUS = "7px";

export const BASE_COLORS = {
  white: "#ffffff",
  darkGreen: "#0A2E34",
  lightGreen: "#01C592",
  yellow: "#FFCB45",
  black: "#000000",
  green: "#008000",
  orange: "#ffb155",
  gray: "#808080",
  lightgrey: "#c5d3e0",
  violet: "#ee82ee",
  lightgreen: "#89dca0",
  pink: "#ffc0cb",
  blue: "#0000ff",
  skyblue: "#35a0dc",
  red: "#ff5252",
} as const;

export const LAYOUT = {
  mobile: {
    paddingVertical: "0.75rem",
    paddingHorizontal: "1rem",
    headerHeight: "4.25rem",
    headerPadding: "1rem",
  },
  desktop: {
    paddingVertical: "1.25rem",
    paddingHorizontal: "2.25rem",
    headerHeight: "3.625rem",
  },
} as const;

export const FONT_FAMILY = {
  main: "Montserrat",
  secondary: "Lato",
} as const;

export const FONT_SIZE = {
 xxxs: "0.7rem",
  xxs: "0.75rem",
  xs: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  xxl: "1.5rem",
  xxxl: "1.625rem",
  xxxxl: "2rem",
} as const;

export const FONT_WEIGHT = {
  thin: "100",
  extraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  extraBold: "800",
  black: "900",
} as const;

export const BREAKPOINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
} as const;

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` =>
  `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};
