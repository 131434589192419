import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteToken,
  deleteUser,
  persistToken,
  readToken,
} from "../../services/localStorage.service";
import { AuthData, login } from "../../api/auth.api";
import { setUser } from "./userSlice";

interface AuthSlice {
  token: string | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: AuthSlice = {
  token: readToken(),
  loading: "idle",
};

export const doLogout = createAsyncThunk(
  "auth/doLogout",
  async (_, { dispatch }) => {
    deleteToken();
    deleteUser();
    dispatch(setUser(null));
  }
);

export const doLogin = createAsyncThunk(
  "auth/doLogin",
  async (loginPayload: AuthData, { dispatch, rejectWithValue }) => {
    try {
      const res = await login(loginPayload, "/fournisseur_login");
      dispatch(setUser(res.fournisseur));
      persistToken(res.token);
      return res.token;
    } catch (error: any) {
      // Handle login error
      console.error("Login failed", error);
      dispatch(setUser(null));
      deleteToken();
      return rejectWithValue(error.response?.data || "Login failed");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        state.token = action.payload;
        state.loading = "succeeded";
      })
      .addCase(doLogin.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(doLogout.fulfilled, (state) => {
        state.token = null;
      });
  },
});

export default authSlice.reducer;
