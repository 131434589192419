import { DatePicker, Drawer } from "antd";
import styled from "styled-components";
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from "../../../../styles/constants";
import { BaseButton } from "../../../common/BaseButton/BaseButton";
import { BaseTypography } from "../../../common/BaseTypography/BaseTypography";
import { BaseInput } from "../../../common/inputs/BaseInput/BaseInput";
import { BaseForm } from "../../../common/forms/BaseForm/BaseForm";

export const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper .ant-drawer-content {
    background-color: var(--card-background-color);

    & .ant-drawer-wrapper-body .ant-drawer-header {
      border: none;
      & .ant-drawer-header-title button {
        display: none;
      }
      & .ant-drawer-header-title .ant-drawer-title h2 {
        font-size: ${FONT_SIZE.xxxxl};
        color: rgb(1, 197, 146);
        margin-bottom: 5px;
      }
      & .ant-drawer-header-title .ant-drawer-title span {
        color: var(--text-light-color);
        font-size: ${FONT_SIZE.xxs};
      }
    }
    & .ant-drawer-wrapper-body .ant-drawer-body {
      padding-top: 0;

      & .ant-collapse {
        border: none;
        box-shadow: none;

        & .ant-collapse-item {
          border: none;
          background-color: var(--drawer-card-background-color);

          & .ant-collapse-header {
            border: none;
            background-color: var(--drawer-card-background-color);
          }

          & .ant-collapse-content {
            border: none;
            background-color: var(--drawer-card-background-color);
          }
        }
      }
    }
    & .ant-drawer-wrapper-body .ant-drawer-footer {
      border: none;
    }
  }
`;

export const FooterForm = styled(BaseForm)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 7px;
  margin-right: 1.2rem;
`;

export const DatePickerFooter = styled(DatePicker)`
  background-color: var(--card-background-color);
  height: 2rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

export const CancelButton = styled(BaseButton)`
  height: 2rem;
  background-color: var(--card-background-color);
  color: #ff5241;
  width: 9rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #ff5241;
    color: #f8f8f8;
    border: 1px solid #f8f8f8;
  }
`;
export const ConfirmButton = styled(BaseButton)`
  height: 2rem;
  background-color: #5caf4a;
  width: 9rem;
  margin-bottom: 1rem;
`;

export const ProductsWrapper = styled.div`
  background-color: var(--card-background-color);
  margin: 0 5px;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-weight: ${FONT_WEIGHT.regular};
`;

export const Product = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  & p {
    margin-bottom: 0;
  }
`;
export const ProductTitle = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xs};
`;
export const ProductDescription = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxs};
`;

export const ProductQuantity = styled(BaseTypography)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12rem;
`;

export const ProductBadge = styled.div`
  padding: 2px 4px;
  width: 6rem;
  text-align: center;
  background-color: #cbcbc7;
  border-radius: 20px;
  font-size: ${FONT_SIZE.xxs};
  color: #000505;
`;

export const ProductButton = styled(BaseButton)`
  background-color: #fa1828;
  border-radius: 50%;
  padding: 0;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    padding: 0;
    margin: 0;
  }
  & span svg {
    width: 10px;
    height: auto;
    margin-top: 2px;
  }
`;

export const InformationSection = styled.div`
  font-weight: ${FONT_WEIGHT.regular};
  border-bottom: 2px solid var(--text-light-color);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const InformationSectionTitle = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
  margin-bottom: 1rem;
`;

export const InformationWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: start;
  font-size: ${FONT_SIZE.xs};
  margin-bottom: 2px;
`;

export const IconAndTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 10rem;
  gap: 7px;

  & p {
    margin: 0;
  }
`;

export const InformationContent = styled.div``;

export const InfoStatut = styled.div`
  border-radius: 15px;
  width: 10rem;
  text-align: center;

  & p {
    margin-bottom: 0;
  }
`;

export const NotesWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NoteLabel = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xl};
`;

export const NoteInput = styled(BaseInput)`
  width: 90%;
  background-color: transparent;
  height: 2rem;
  border-radius: 5px;
`;
