import { Form, Input } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  border-top: 1px solid var(--text-sider-secondary-color);
`;

export const MessageInput = styled(Input)`
  border-radius: 0;
  border: none;
  background-color: transparent;
  height: 3rem;
  & .ant-form-item-control-input-content input {
    background-color: transparent;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
`;
