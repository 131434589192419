import React from "react";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import * as S from "./OrdersPage.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PendingOrders } from "../../components/orders/pendingOrders/PendingOrders";

const PendingOrdersPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const theme = useSelector((state: RootState) => state.theme.theme);
  

  return (
    <>
      <PageTitle>Pending Orders</PageTitle>
      <PendingOrders />
    </>
  );
};

export default PendingOrdersPage;
