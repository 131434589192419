import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { notificationController } from "../../controllers/notificationController";

/**
 * Log a warning and show a toast!
 */
export const errorLoggingMiddleware: Middleware =
  () => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      //notificationController.error({ message: action.payload });
      console.log(action.payload);
    }

    return next(action);
  };
