import styled from "styled-components";
import { Card, Dropdown, Select } from "antd";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { BaseButton } from "../../../common/BaseButton/BaseButton";
import { DeleteFilled } from "@ant-design/icons";
import { BASE_COLORS } from "../../../../styles/constants";

export const OrderCard = styled(Card)`
  width: 15rem;
  border: none;
  background-color: var(--card-background-color);
  .ant-card-head {
    border: none;
  }
  .ant-card-actions {
    background-color: transparent;
    border: none;
    margin-top: -1rem;
  }
`;

export const StatusSelect = styled(Select)`
  width: 100%;
  .ant-select-arrow {
    color: var(--text-light-color);
  }
  .ant-select-selector .ant-select-selection-placeholder {
    color: var(--text-main-color);
  }
`;

export const Circle = styled.span<{ color: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export const SelectWrapper = styled.div`
  margin-top: -10px;
  margin-bottom: -5px;
`;

export const ActionRow = styled(BaseRow)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const DisplayButton = styled(BaseButton)`
  width: 73%;
  background-color: var(--heading-color);
  color: var(--layout-body-bg-color);
`;

export const DeleteButton = styled(BaseButton)`
  background-color: transparent;
  color: ${BASE_COLORS.red};
  border: 1px solid var(--text-light-color);

  &:focus,
  &:hover {
    border: 1px solid #ff5252;
    color: #ff5252;
  }
`;

export const DeleteIcon = styled(DeleteFilled)`
  margin-right: 0.5rem;
  color: var(--heading-color);
  font-size: 1.5rem;
`;
