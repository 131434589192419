import styled from "styled-components";
import { Card } from "antd";
import { BaseRow } from "../common/BaseRow/BaseRow";

export const Main = styled.div`
  margin: 1rem;
  height: 100%;
`;

export const CardsWrapper = styled.div`
  margin-left: 3rem;
  margin-top: 1rem;
`;

export const NoDataFound = styled.div`
  width: 100%;
  height: 16.5rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
`;
