import styled, { css } from "styled-components";
import { BaseCol } from "../common/BaseCol/BaseCol";
import { media, LAYOUT, BASE_COLORS } from "../../styles/constants";
import { BaseCollapse } from "../common/BaseCollapse/BaseCollapse";
import { BurgerIcon } from "../common/Burger/BurgerIcon";
import { Input } from "antd";
import { BellFilled, BellOutlined, MessageFilled } from "@ant-design/icons";

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role="img"],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role="img"] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(BaseCol)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const MobileBurger = styled(BurgerIcon)`
  z-index: 999;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  padding-left: 0;
`;

interface ProfileColumnInput {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(BaseCol)<ProfileColumnInput>`
  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical}
          ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const SearchInput = styled(Input)`
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: var(--card-background-color);

  & .ant-input-prefix span svg {
    fill: var(--text-sider-secondary-color);
  }

  & input {
    background-color: var(--card-background-color);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NotificationWrapper = styled.div`
  height: 100%;
  width: 2rem;
  position: relative;

  cursor: pointer;
`;

export const NotificationIcon = styled(BellFilled)`
  & svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

export const MessageIcon = styled(MessageFilled)`
  & svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

export const NotificationRed = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${BASE_COLORS.red};
  position: absolute;
  top: 0;
  right: 5px;
`;
