import { SupplierModel } from "../domain/supplierModel";
import { readToken } from "../services/localStorage.service";
import axios, { AxiosError } from "axios";

export interface AuthData {
  email: string;
  password: string;
}

export interface AuthResponse {
  token: string;
  fournisseur: SupplierModel;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  config.headers = { ...config.headers, Authorization: `${readToken()}` };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const login = async (
  loginPayload: AuthData,
  apiUrl: string
): Promise<AuthResponse> =>
  await axiosInstance
    .post<AuthResponse>(apiUrl, { ...loginPayload })
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Login failed", error);
      throw error.response?.data || "Login failed";
    });
