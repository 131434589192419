import { graphic } from "echarts";
import { BASE_COLORS } from "../constants";
import { ITheme } from "../types";
import { shadeColor } from "../../utils/utils";

export const darkColorsTheme: ITheme = {
  primary: "#339CFD",
  primary1: "#7568f6",
  primaryGradient: "linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)",
  light: "#696969",
  secondary: "#0072DD",
  error: "#FF5252",
  warning: "#FFB765",
  success: "#57D682",
  background: "#111315",
  secondaryBackground: "#F0F0EC",
  secondaryBackgroundSelected: shadeColor("#1c2137", -5),
  additionalBackground: "#fffff",
  collapseBackground: "#1D203E",
  siderBackground: "#121430",
  spinnerBase: "#339CFD",
  scroll: "#797C9A",
  border: "#ffffff",
  textMain: "#ffffff",
  textLight: "#ACACAC",
  textSuperLight: "#444",
  textSecondary: "#ffffff",
  textDark: "#404040",
  textSiderPrimary: "#339CFD",
  textSiderSecondary: "#9C9CA1",
  subText: "#a9a9a9",
  shadow: "rgba(0, 0, 0, 0.07)",
  boxShadow: "none",
  boxShadowHover: "none",
  notificationSuccess: "#EFFFF4",
  notificationPrimary: "#D7EBFF",
  notificationWarning: "#FFF4E7",
  notificationError: "#FFE2E2",
  heading: BASE_COLORS.white,
  borderBase: "#a9a9a9",
  disable: "#7e7e7e",
  layoutBodyBg: "#111315",
  layoutHeaderBg: "#111315",
  layoutSiderBg: "#111315",
  inputPlaceholder: "rgba(255, 255, 255, 0.5)",
  inputBackground: "#111315",
  itemHoverBg: "#1c2137",
  backgroundColorBase: "#1c2137",
  avatarBg: "#1c2137",
  alertTextColor: "#000",
  icon: "#a9a9a9",
  iconHover: "#ffffff",
  cardBackground: "#2D2D2D",
  iconBackgroundColor: "#ffffff",
  ActiveSiderLinkBg: "#2D2D2D",
  siderActiveLink: "#ffffff",
  drawerCardBackground: "#403f3f",
};

export const antDarkColorsTheme = {
  successBg: "#e6fff2",
  successBorder: "#79fcc4",
};
