import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "../../pages/LoginPage";
import MainLayout from "../layouts/main/MainLayout/MainLayout";
import RequireAuth from "../router/RequireAuth";
import { withLoading } from "../../hocs/withLoading.hoc";
import DashboardPage from "../../pages/DashboardPages/DashboardPage";
import ReportingPage from "../../pages/ReportingPage/ReportingPage";
import PendingOrdersPage from "../../pages/OrdersPages/PendingOrdersPage";
import CurrentOrdersPage from "../../pages/OrdersPages/CurrentOrdersPage";
import PlacedOrdersPage from "../../pages/OrdersPages/PlacedOrdersPage";
import MyRestaurantsPage from "../../pages/RestaurantsPage/MyRestaurantsPage";
// import AllRestaurantsPage from "../../pages/RestaurantsPage/AllRestaurantsPage";
import InventoryPage from "../../pages/InventoryPage/InventoryPage";
import SettingsPage from "../../pages/SettingsPage/SettingsPage";
import ConversationPage from "../../pages/ConversationPage/ConversationPage";
import PrePaidOrders from "../reporting/DetailedReports/PrePaidOrders/PrePaidOrders";
import PopularProductsDetailed from "../reporting/DetailedReports/PopularProducts/PopularProducts";
import UnpaidOrders from "../reporting/DetailedReports/UnpaidOrders/UnpaidOrders";
import TopAccounts from "../reporting/DetailedReports/TopAccounts/TopAccounts";

const AuthLayout = React.lazy(() => import("../layouts/AuthLayout/AuthLayout"));
const ServerErrorPage = React.lazy(() => import("../../pages/ServerErrorPage"));
const Error404Page = React.lazy(() => import("../../pages/Error404Page"));
const Logout = React.lazy(() => import("./Logout"));

const Reporting = withLoading(ReportingPage);
const Dashboard = withLoading(DashboardPage);
const MyRestaurant = withLoading(MyRestaurantsPage);
// const AllRestaurant = withLoading(AllRestaurantsPage);
const PendingOrders = withLoading(PendingOrdersPage);
const CurrentOrders = withLoading(CurrentOrdersPage);
const PlacedOrders = withLoading(PlacedOrdersPage);
const Inventory = withLoading(InventoryPage);
const Settings = withLoading(SettingsPage);
const Conversations = withLoading(ConversationPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
//UI
const PrePaidOrdersUI = withLoading(PrePaidOrders);
const PopularProductsDetailedUI = withLoading(PopularProductsDetailed);
const UnpaidOrdersUI = withLoading(UnpaidOrders);
const TopAccountsUI = withLoading(TopAccounts);
// Profile

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={"/reporting"}>
            <Route path={""} element={<Reporting />} />
            <Route path="prePaidOrders" element={<PrePaidOrdersUI />} />
            <Route
              path="PopularProductsDetailed"
              element={<PopularProductsDetailedUI />}
            />
            <Route path="unpaidOrders" element={<UnpaidOrdersUI />} />
            <Route path="topAccounts" element={<TopAccountsUI />} />
          </Route>
          <Route path="/restaurants" element={<MyRestaurant />}></Route>
          {/* <Route path="/allRestaurants" element={<AllRestaurant />}></Route> */}
          <Route path={"/conversations"} element={<Conversations />} />
          <Route path={"/pending-orders"} element={<PendingOrders />} />
          <Route path={"/current-orders"} element={<CurrentOrders />} />
          <Route path={"/palced-orders"} element={<PlacedOrders />} />
          <Route path={"/inventory"} element={<Inventory />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
