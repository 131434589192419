import {
  ConnectedRestaurant,
  InviteList,
  Restaurant,
} from "../domain/restaurantModel";
import { readToken } from "../services/localStorage.service";
import axios, { AxiosError, AxiosResponse } from "axios";

export interface RestaurantData {
  magasin_id: number;
}

export interface RestaurantPackageData {
  magasin_id: number;
  package_id: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const fetchAllRestaurantsApi = async (
  apiUrl: string
): Promise<Restaurant[]> =>
  await axiosInstance
    .get<Restaurant[]>(apiUrl)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("fetch all restaurants failed", error);
      throw error.response?.data || "fetch all restaurants failed";
    });

export const fetchAllInvitesApi = async (apiUrl: string): Promise<InviteList> =>
  await axiosInstance
    .get<InviteList>(apiUrl)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("fetch invites failed", error);
      throw error.response?.data || "fetch invites failed";
    });

export const fetchConnectedRestaurantsApi = async (
  apiUrl: string
): Promise<ConnectedRestaurant[]> =>
  await axiosInstance
    .get<ConnectedRestaurant[]>(apiUrl)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("fetch connected restaurants failed", error);
      throw error.response?.data || "fetch connected restaurants failed";
    });

export const acceptDemandeApi = async (
  restauData: RestaurantPackageData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      apiUrl,
      restauData
    );
    return response.data;
  } catch (error: any) {
    console.error("Accept invite failed", error);
    throw error.response?.data || "Accept invite failed";
  }
};

export const rejectDemandeApi = async (
  restauData: RestaurantData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      apiUrl,
      { data: restauData }
    );
    return response.data;
  } catch (error: any) {
    console.error("Reject invite failed", error);
    throw error.response?.data || "Reject invite failed";
  }
};

export const deleteConnectedStoreApi = async (
  restauData: RestaurantData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.delete<any>(
      apiUrl,
      { data: restauData }
    );
    return response.data;
  } catch (error: any) {
    console.error("Delete invite failed", error);
    throw error.response?.data || "Delete invite failed";
  }
};
