import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Ingredient,
  fetchIngredientsApi,
  addIngredientsApi,
  updateIngredientApi,
  deleteIngredientApi,
} from "../../api/ingredients.api";

interface IngredientsState {
  ingredients: {
    produits: Ingredient[];
    totalArticle: number;
    stockFaible: number;
    ruptureStock: number;
  };

  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: IngredientsState = {
  ingredients: {
    produits: [],
    totalArticle: 0,
    stockFaible: 0,
    ruptureStock: 0,
  },

  loading: "idle",
};

const API_URL = "/produits";

export const fetchIngredients: any = createAsyncThunk(
  "Ingredients/fetchIngredients",
  async () => {
    const ingredients: Ingredient[] = await fetchIngredientsApi(API_URL);
    return ingredients;
  }
);

export const deleteIngredient: any = createAsyncThunk(
  "Ingredients/deleteIngredient",
  async (IngredientId: number, { dispatch }) => {
    const ingredient = deleteIngredientApi(IngredientId, API_URL);
    dispatch(fetchIngredients());
    return ingredient;
  }
);

export const addIngredient: any = createAsyncThunk(
  "Ingredients/addIngredient",
  async (newIngredient: Ingredient, { dispatch }) => {
    const ingredient = await addIngredientsApi(newIngredient, API_URL);
    await dispatch(fetchIngredients());
    return ingredient;
  }
);

export const updateIngredient: any = createAsyncThunk(
  "Ingredients/updateIngredient",
  async (updatedIngredient: any, { dispatch }) => {
    const ingredient = updateIngredientApi(updatedIngredient, API_URL);
    await dispatch(fetchIngredients());
    return ingredient;
  }
);

const ingredientsSlice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIngredients.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchIngredients.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.ingredients = action.payload;
      })
      .addCase(fetchIngredients.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default ingredientsSlice.reducer;
