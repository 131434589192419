import React from "react";
import { BaseOptionType } from "antd/lib/select";
import { Button, InputNumber, Upload, UploadProps, message } from "antd";
import { Ingredient } from "../../../api/ingredients.api";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BaseInput } from "../../common/inputs/BaseInput/BaseInput";
import { BaseSelect } from "../../common/selects/BaseSelect/BaseSelect";
import { UploadOutlined } from "@ant-design/icons";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text" | "unitSelect" | "file" | "categorySelect";
  options: BaseOptionType[];
  record: Ingredient;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  ...restProps
}) => {
  // const props: UploadProps = {
  //   name: "image",
  //   action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "unitSelect" ? ( // Adjust for customSelect
      <BaseSelect options={options} />
    ) : inputType === "categorySelect" ? ( // Adjust for customSelect
      <BaseSelect
        options={options}
        defaultValue={record.fournisseur_categories_id}
      />
    ) : inputType === "file" ? (
      // <Upload {...props} maxCount={1}>
      //   <Button icon={<UploadOutlined />}></Button>
      // </Upload>
      <img
        src={
          record.image === "image"
            ? "assets/default-ingredients.jpg"
            : process.env.REACT_APP_IMAGE_URL +
              "produits_images/" +
              record.image
        }
        srcSet="ingredient image"
        style={{ width: "2.5rem", height: "auto", borderRadius: "5px" }}
      />
    ) : (
      <BaseInput />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <BaseForm.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </BaseForm.Item>
      ) : (
        children
      )}
    </td>
  );
};
