import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { Inventory } from "../../components/inventory/Inventory";
import { Settings } from "../../components/settings/Settings";
import { Conversations } from "../../components/conversations/Conversations";

const ConversationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("common.menu.conversations")}</PageTitle>
      <Conversations />
    </>
  );
};

export default ConversationPage;
