import { useTranslation } from "react-i18next";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { CardPlus } from "./CardPlus/CardPlus";
import * as S from "./GenralReporting.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import dollars from "../../../assets/icons/dashboard/dollars.png";
import scaleUp from "../../../assets/icons/dashboard/scaleUp.png";
import vMark from "../../../assets/icons/dashboard/vMark.png";
import time from "../../../assets/icons/dashboard/time.png";
import star from "../../../assets/icons/reporting/Star.png";
import starLight from "../../../assets/icons/reporting/StarLight.png";
import heart from "../../../assets/icons/reporting/heart.png";
import heartLight from "../../../assets/icons/reporting/heartLight.png";
import average from "../../../assets/icons/reporting/average.png";
import averageLight from "../../../assets/icons/reporting/averageLight.png";
import timeLight from "../../../assets/icons/dashboard/timeLight.png";
import vMarkLight from "../../../assets/icons/dashboard/vMarkLight.png";
import scaleUpLight from "../../../assets/icons/dashboard/scaleLight.png";

const GeneralReporting: React.FC = () => {
  const { t } = useTranslation();
  const theme = useSelector((state: RootState) => state.theme.theme);

  const vMarkIcon = theme === "dark" ? vMark : vMarkLight;
  const timeIcon = theme === "dark" ? time : timeLight;
  const scaleUpIcon = theme === "dark" ? scaleUp : scaleUpLight;
  const heartIcon = theme === "dark" ? heart : heartLight;
  const starIcon = theme === "dark" ? star : starLight;
  const averageIcon = theme === "dark" ? average : averageLight;
  return (
    <S.StyledRow gutter={[16, 16]} style={{ width: "100%" }}>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={dollars}
          numberResult={32550}
          title="Revenu"
          unit="DHS"
          key={1}
          withButton={false}
          route=""
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={vMarkIcon}
          numberResult={32550}
          title="Commandes prépayées"
          unit="DHS"
          key={2}
          withButton={true}
          route="/prePaidOrders"
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={timeIcon}
          numberResult={32550}
          title="Commandes impayées"
          unit="DHS"
          key={3}
          withButton={true}
          route="/unpaidOrders"
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={scaleUpIcon}
          numberResult={80}
          title="Performance de livraison"
          unit="%"
          key={4}
          withButton={false}
          route=""
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={averageIcon}
          numberResult={3500}
          title="Valeur moyenne des commandes"
          unit="DHS"
          key={5}
          withButton={false}
          route=""
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={starIcon}
          numberResult={""}
          title="Produits populaires"
          unit=""
          key={7}
          withButton={true}
          route="/PopularProductsDetailed"
        />
      </BaseCol>
      <BaseCol xs={24} md={12} lg={6}>
        <CardPlus
          icon={heartIcon}
          numberResult={""}
          title="Les plus grands comptes"
          unit=""
          key={6}
          withButton={true}
          route="/topAccounts"
        />
      </BaseCol>
    </S.StyledRow>
  );
};

export default GeneralReporting;
