import React, { useState } from "react";
import * as S from "./BasicTable.styles";
import { Pagination } from "../../../../../api/table.api";

interface TableProps {
  columns: any;
  loading: boolean;
}

export const BasicTable: React.FC<TableProps> = ({ columns, loading }) => {
  const popularProducts: any = [
    {
      name: "potato",

      somme: 234,
    },
    {
      name: "tomato",
      somme: 234,
    },
    {
      name: "orange",
      somme: 234,
    },
  ];
  const dataSource = popularProducts?.map((item: any, index: number) => ({
    classement: index + 1,
    name: item.name,
    quantity: item.somme,
  }));
  const [pagination, setPagination]: any = useState<any>({
    current: 1,
    pageSize: 10,
    total: dataSource ? dataSource.length : 0,
    position: ["bottomCenter"],
  });

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination);
  };

  const slicedData = dataSource
    ? dataSource?.slice(
        (pagination?.current - 1) * pagination?.pageSize,
        pagination?.current * pagination?.pageSize
      )
    : [];

  return (
    <>
      <S.TableView
        columns={columns}
        dataSource={slicedData}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};
