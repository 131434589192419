import styled, { css } from "styled-components";
import { media, LAYOUT } from "../../../../styles/constants";
import { BaseLayout } from "../../../common/BaseLayout/BaseLayout";

interface HeaderInput {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(BaseLayout.Header)<HeaderInput>`
  line-height: 1.5;
  width: 97%;
  height: 3.625rem;
  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical}
      ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
  }

  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;
