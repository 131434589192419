import { useNavigate } from "react-router-dom";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import * as S from "./CardPlus.styles";

interface CardProps {
  title: string;
  numberResult: number | null | undefined | "";
  icon: string;
  unit: string;
  withButton: boolean;
  route: string;
}

export const CardPlus: React.FC<CardProps> = ({
  title,
  numberResult,
  icon,
  unit,
  withButton,
  route,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <S.SummaryCard>
        <BaseRow justify={"space-between"}>
          <S.ImageWrapper>
            <S.CardImage
              src={icon}
              preview={false}
              alt="Card Image"
            ></S.CardImage>
          </S.ImageWrapper>
          {withButton && (
            <S.CardButton onClick={() => navigate("/reporting" + route)}>
              Plus
            </S.CardButton>
          )}
        </BaseRow>

        <S.CardBody>
          <S.CardTitle>{title}</S.CardTitle>
          <S.CardNumber>{numberResult + "  " + unit}</S.CardNumber>
        </S.CardBody>
      </S.SummaryCard>
    </>
  );
};
