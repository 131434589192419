import { useSelector } from "react-redux";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "./ConversationsMessages.styles";
import { RootState } from "../../../store/store";
import { Message } from "../../../api/conversations.api";
import { ConversationForm } from "../ConversationForm/ConversationForm";

export const ConversationMessages: React.FC = () => {
  const { messages } = useSelector((state: RootState) => state.conversations);
  const list: Message[] = messages.messages.data;
  const magasin_id: number = messages.magasin_id;

  function parseDateTime(dateTimeString: string): Date {
    const parts = dateTimeString.split(" ");
    const timeParts = parts[0].split(":");
    const dateParts = parts[1].split("-");
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    return new Date(year, month - 1, day, hour, minute);
  }

  return (
    <S.Main>
      <S.ConversationWrapper>
        {list.map((m: Message) => {
          const conversationDateTime = parseDateTime(m.time);
          const today = new Date();
          const isToday =
            conversationDateTime.getFullYear() === today.getFullYear() &&
            conversationDateTime.getMonth() === today.getMonth() &&
            conversationDateTime.getDate() === today.getDate();

          const displayTime = isToday
            ? conversationDateTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : conversationDateTime.toLocaleDateString();
          return (
            <S.Message sender={m.sender}>
              <BaseTypography>{m.body}</BaseTypography>
              <BaseTypography style={{ textAlign: "end" }}>
                {displayTime}
              </BaseTypography>
            </S.Message>
          );
        })}
      </S.ConversationWrapper>
      <ConversationForm magasin_id={magasin_id} />
    </S.Main>
  );
};
