import axios, { AxiosError, AxiosResponse } from "axios";
import { readToken } from "../services/localStorage.service";

export interface Package {
  id: number;
  name: string;
  percentage: number;
  fournisseur_id: number;
  created_at: string;
  updated_at: string;
}

export interface PackageData {
  name: string;
  percentage: number;
}

export interface BasicTableData {
  data: Package[];
  // pagination: Pagination;
}

export interface EditableTableData extends BasicTableData {
  data: Package[];
}

const apiUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "HTTP error response:",
        error.response.data || "No response data available"
      );
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("HTTP no response:", error.request);
      return Promise.reject({
        message: "No response received from the server",
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("HTTP request setup error:", error.message);
      return Promise.reject({ message: "Error setting up the request" });
    }
  }
);

export const fetchPackagesApi = async (apiUrl: string): Promise<Package[]> => {
  try {
    const response: AxiosResponse<Package[]> = await axiosInstance.get<any>(
      apiUrl
    );
    return response.data;
  } catch (error: any) {
    console.error("Fetch packages failed", error);
    throw error.response?.data || "Fetch packages failed";
  }
};

export const getEditableTablePackageData = (
  data: Package[]
): Promise<EditableTableData> => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: data,
      });
    }, 1000);
  });
};

export const addPackageApi = async (
  newPackage: PackageData,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post<any>(
      apiUrl,
      newPackage
    );
    return response.data;
  } catch (error: any) {
    console.error("Add Package failed", error);
    throw error.response?.data || "Add Package failed";
  }
};

export const deletePackageApi = async (
  PackageId: number,
  apiUrl: string
): Promise<void> => {
  try {
    await axiosInstance.delete<void>(`${apiUrl}/${PackageId}`);
  } catch (error: any) {
    console.error("Delete Package failed", error);
    throw error.response?.data || "Delete Package failed";
  }
};

export const updatePackageApi = async (
  updatedPackage: Package,
  apiUrl: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put<any>(
      `${apiUrl}/${updatedPackage.id}`,
      updatedPackage
    );
    return response.data;
  } catch (error: any) {
    console.error("Update package failed", error);
    throw error.response?.data || "Update package failed";
  }
};
