import styled from "styled-components";

export const ProductItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
`;

export const PositionNumber = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--text-main-color);
  border-radius: 50%;
  margin-right: 1rem;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const DeliveryInfo = styled.div`
  display: flex;
  margin-top: -4px;
`;
