import { Modal } from "antd";
import styled from "styled-components";
import { BaseTypography } from "../../../common/BaseTypography/BaseTypography";
import { FONT_FAMILY, FONT_SIZE } from "../../../../styles/constants";

export const StyledModal = styled(Modal)`
  margin-left: auto;
  margin-right: 2rem;

  & .ant-modal-content {
    height: 700px;
    overflow-y: auto;
    background-color: var(--card-background-color);

    & .ant-modal-header {
      background-color: var(--card-background-color);
      border-bottom: none;
    }
  }
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
`;

export const Avatar = styled.img`
  width: 3rem;
  height: auto;
  border-radius: 7px;
`;

export const Notification = styled.div``;

export const NotificationTitle = styled(BaseTypography)`
  font-size: ${FONT_SIZE.lg};
`;
export const NotificationDate = styled(BaseTypography)`
  font-size: ${FONT_SIZE.md};
`;
