import { graphic } from "echarts";
import { BASE_COLORS } from "../constants";
import { ITheme } from "../types";
import { shadeColor } from "../../utils/utils";

export const lightColorsTheme: ITheme = {
  primary: "#056a6a",
  primary1: "#f5f5f5",
  primaryGradient: "linear-gradient(211.49deg, #006CCF 15.89%, #00509A 48.97%)",
  light: "#adcccc",
  secondary: "#0A2E34",
  error: "#FF5252",
  warning: "#FFCB45",
  success: "#30AF5B",
  background: "#F0F0EC",
  secondaryBackground: "#111315",
  secondaryBackgroundSelected: shadeColor("#F8FBFF", -5),
  additionalBackground: "black",
  collapseBackground: "rgb(106, 173, 173)",
  siderBackground: "#F0F0EC",
  spinnerBase: "#0A2E34",
  scroll: "#0A2E34",
  border: "#68D6A4",
  textMain: "#111315",
  textLight: "#ACACAC",
  textSuperLight: "#dff5f5",
  textSecondary: BASE_COLORS.white,
  textDark: "#404040",
  textSiderPrimary: "#1BB2B2",
  textSiderSecondary: "#111315",
  subText: "rgba(0, 0, 0, 0.45)",
  shadow: "rgba(0, 0, 0, 0.07)",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.07)",
  boxShadowHover: "0 4px 16px 0 rgba(0, 0, 0, 0.2)",
  notificationSuccess: "#EFFFF4",
  notificationPrimary: "#D7EBFF",
  notificationWarning: "#FFF4E7",
  notificationError: "#FFE2E2",
  heading: "#0A2E34",
  borderBase: "#97a4a6",
  disable: "rgba(0, 0, 0, 0.25)",
  layoutBodyBg: "#F0F0EC",
  layoutHeaderBg: "#F0F0EC",
  layoutSiderBg: "#F0F0EC",
  inputPlaceholder: "#404040",
  inputBackground: BASE_COLORS.white,
  itemHoverBg: "#f5f5f5",
  backgroundColorBase: "#F5F5F5",
  avatarBg: "#1BB2B2",
  alertTextColor: BASE_COLORS.white,
  icon: "#a9a9a9",
  iconHover: "rgba(0, 0, 0, 0.75)",
  cardBackground: "#FFFFFF",
  iconBackgroundColor: "#323232",
  ActiveSiderLinkBg: "#CBCBC7",
  siderActiveLink: "#000000",
  drawerCardBackground: "#f0f0ec",
};
