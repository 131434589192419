import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSettingsApi, updateDetailsApi } from "../../api/settings.api";

interface PackagesState {
  settingsInfo: any;

  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: PackagesState = {
  settingsInfo: [],
  loading: "idle",
};

const API_URL = "/updateDetails";

export const updateDetails: any = createAsyncThunk(
  "Settings/updateDetails",
  async (settingsData: any, { dispatch }) => {
    const settings = await updateDetailsApi(settingsData, API_URL);
    dispatch(fetchSettings());
    return settings;
  }
);

export const fetchSettings: any = createAsyncThunk(
  "Settings/fetchSettings",
  async () => {
    const packages: any = await fetchSettingsApi("/fournisseurInfo");
    return packages;
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.settingsInfo = action.payload.data;
      })
      .addCase(updateDetails.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchSettings.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.settingsInfo = action.payload.data;
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default settingsSlice.reducer;
