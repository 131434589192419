import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { BaseCol } from "../../components/common/BaseCol/BaseCol";
import * as S from "./DashboardPage.styles";
import { Card } from "../../components/dashboard/Card/Card";
import dollars from "../../assets/icons/dashboard/dollars.png";
import scaleUp from "../../assets/icons/dashboard/scaleUp.png";
import vMark from "../../assets/icons/dashboard/vMark.png";
import time from "../../assets/icons/dashboard/time.png";
import timeLight from "../../assets/icons/dashboard/timeLight.png";
import vMarkLight from "../../assets/icons/dashboard/vMarkLight.png";
import scaleUpLight from "../../assets/icons/dashboard/scaleLight.png";
import { BaseRow } from "../../components/common/BaseRow/BaseRow";
import { BaseTypography } from "../../components/common/BaseTypography/BaseTypography";
import { FONT_SIZE } from "../../styles/constants";
import { DatePicker, DatePickerProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { TopProducts } from "../../components/dashboard/TopProducts/TopProducts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ChartDashboard } from "../../components/dashboard/Chart/ChartDashboard";
import DashboardSummary from "../../components/dashboard/dashboard/DashboardSummary";
import moment from "moment";
import { getStatistiques } from "../../store/slices/dashboardSlice";
import { DashboardData } from "../../api/dashboard.api";

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const datePickerIcon = <DownOutlined />;

  const theme = useSelector((state: RootState) => state.theme.theme);
  const { commandesCompletees } = useSelector(
    (state: RootState) => state.dashboard
  );
  const dollarIcon = theme === "dark" ? dollars : vMarkLight;
  const vMarkIcon = theme === "dark" ? vMark : vMarkLight;
  const timeIcon = theme === "dark" ? time : timeLight;
  const scaleUpIcon = theme === "dark" ? scaleUp : scaleUpLight;

  const dispatch = useDispatch();

  const dateFormat = "YYYY/MM/DD";
  const [date, setDate] = useState<string>(moment().format("YYYY/MM/DD"));

  const onChange: DatePickerProps["onChange"] = (dates, dateString) => {
    const data: DashboardData = {
      date: dateString,
    };
    dispatch(getStatistiques(data));
  };

  useEffect(() => {
    const data: DashboardData = {
      date: date,
    };
    dispatch(getStatistiques(data));
  }, [date]);

  const desktopLayout = (
    <S.Main>
      <S.FlexRow>
        <BaseTypography style={{ fontSize: FONT_SIZE.xxxxl }}>
          Tableau de bord
        </BaseTypography>
        {/* <DatePicker
          style={{
            height: "2rem",
            border: "none",
            backgroundColor: "var(--card-background-color)",
            marginRight: "1rem",
          }}
          suffixIcon={datePickerIcon}
          defaultValue={moment()}
          onChange={onChange}
          format={dateFormat}
        ></DatePicker> */}
      </S.FlexRow>

      <DashboardSummary></DashboardSummary>

      <BaseRow gutter={[16, 16]} style={{ width: "100%" }}>
        <BaseCol span={9}>
          {/*Add the items Later */}
          <TopProducts title="Produit populaire"></TopProducts>
        </BaseCol>
        <BaseCol span={15}>
          <ChartDashboard
            title="Commandes complétées"
            data={Object.values(commandesCompletees)}
          ></ChartDashboard>
        </BaseCol>
      </BaseRow>
    </S.Main>
  );

  const mobileAndTabletLayout = (
    <S.Main>
      <S.FlexRow>
        <BaseTypography style={{ fontSize: FONT_SIZE.xxl }}>
          Tableau de bord
        </BaseTypography>
        {/* <DatePicker
          style={{
            height: "2rem",
            border: "none",
            backgroundColor: "var(--card-background-color)",
            marginRight: "1rem",
          }}
          suffixIcon={datePickerIcon}
        ></DatePicker> */}
      </S.FlexRow>
      <DashboardSummary></DashboardSummary>
      <BaseRow style={{ width: "100%" }}>
        {/*Add the items Later */}
        <TopProducts title="Produit populaire"></TopProducts>
      </BaseRow>
      <BaseRow style={{ width: "100%" }}>
        <ChartDashboard
          title="Commandes complétées"
          data={Object.values(commandesCompletees)}
        ></ChartDashboard>
      </BaseRow>
    </S.Main>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
