import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./LoginForm.styles";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import * as Auth from "../../layouts/AuthLayout/AuthLayout.styles";
import logoDark from "../../../assets/logo.png";
import logoLight from "../../../assets/logoLight.png";
import { BASE_COLORS } from "../../../styles/constants";
import { useResponsive } from "../../../hooks/useResponsive";
import appleLogoLight from "../../../assets/icons/apple-logo.png";
import appleLogoDark from "../../../assets/icons/apple_logo_dark.png";
import playStoreLogo from "../../../assets/icons/PlayStore-logo.png";
import { doLogin } from "../../../store/slices/authSlice";
import { AuthData } from "../../../api/auth.api";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { notificationController } from "../../../controllers/notificationController";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Form } from "antd";

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  const dispatch: any = useAppDispatch();
  const { loading } = useSelector((state: RootState) => state.auth);
  const isLoading: boolean =
    loading !== "idle" && loading !== "succeeded" && loading !== "failed";

  const theme = useSelector((state: RootState) => state.theme.theme);
  const [form] = Form.useForm();

  const logo = theme === "dark" ? logoDark : logoLight;
  const applelogo = theme === "dark" ? appleLogoLight : appleLogoDark;

  const handleLogin = async () => {
    const values: AuthData = await form.validateFields();
    console.log(values);
    const loginData: AuthData = {
      email: values.email,
      password: values.password,
    };
    await dispatch(doLogin(loginData))
      .unwrap()
      .then(() => {
        navigate("/");
      })
      .catch((error: any) => {
        console.error("something went wrong: ", error);
      });
  };

  const desktopLayout = (
    <>
      <S.LoginWrapper span={24}>
        <S.LogoImageContainer style={{ marginLeft: "10px" }}>
          <S.LogoImage src={logo} preview={false} alt="User Image" />
        </S.LogoImageContainer>
        <Auth.FormTitle>
          {t("common.login")}

          <span
            style={{
              display: "block",
              marginTop: "1rem",
              color: "#5cae4a",
            }}
          >
            {t("common.loginSpan")}
          </span>
        </Auth.FormTitle>

        <S.LoginForm
          form={form}
          layout="vertical"
          onFinish={handleLogin}
          requiredMark="optional"
        >
          <Auth.FormItem
            name="email"
            label={t("common.email")}
            rules={[
              { required: true, message: "Ce champ est obligatoire!" },
              {
                type: "email",
                message: "Veuillez saisir un email valide",
              },
            ]}
          >
            <Auth.FormInput placeholder={t("common.emailPlaceholder")} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t("common.password")}
            name="password"
            rules={[{ required: true, message: "Ce champ est obligatoire!" }]}
          >
            <Auth.FormInputPassword
              placeholder={t("common.passwordPlaceholder")}
              visibilityToggle
            />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <Link to="/auth/forgot-password">
              <S.ForgotPasswordText>
                {t("common.forgotPass")}
              </S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>

          <BaseForm.Item>
            <Auth.SubmitButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t("common.loginButtom")}
            </Auth.SubmitButton>
          </BaseForm.Item>
        </S.LoginForm>
        <S.SpanLinedWrapper>
          <S.SpanLined>{t("common.download")}</S.SpanLined>
        </S.SpanLinedWrapper>
        <S.DownloadWrapper>
          <S.DownloadButton>
            <S.DownloadImage
              src={applelogo}
              preview={false}
              style={{ marginBottom: "2px" }}
              alt="User Image"
            />
            <S.DownloadTextWrapper>
              <S.DownloadText>Télécharger sur le</S.DownloadText>
              <S.DownloadSpan>App Store</S.DownloadSpan>
            </S.DownloadTextWrapper>
          </S.DownloadButton>
          <S.DownloadButton>
            <S.DownloadImage
              src={playStoreLogo}
              preview={false}
              alt="User Image"
            />
            <S.DownloadTextWrapper>
              <S.DownloadText>Télécharger sur le</S.DownloadText>
              <S.DownloadSpan>Google Play</S.DownloadSpan>
            </S.DownloadTextWrapper>
          </S.DownloadButton>
        </S.DownloadWrapper>
        <S.SpanLinedWrapper style={{ marginBottom: "5px" }}>
          <S.SpanLined>{t("common.or")}</S.SpanLined>
        </S.SpanLinedWrapper>
        <S.SpanLinedWrapper>
          <Auth.SignUpButton
            type="primary"
            onClick={() =>
              (window.location.href = "https://dimahani.ma/contact")
            }
          >
            {t("common.noAccount")}
          </Auth.SignUpButton>
        </S.SpanLinedWrapper>
      </S.LoginWrapper>
    </>
  );

  return <>{desktopLayout}</>;
};
