import nightModeReducer from "./nightModeSlice";
import themeReducer from "./themeSlice";
import userReducer from "./userSlice";
import authReducer from "./authSlice";
import restaurantsReducer from "./restaurantsSlice";
import ordersReducer from "./ordersSlice";
import dashboardReducer from "./dashboardSlice";
import ingredientsReducer from "./ingredientsSlice";
import packagesReducer from "./packagesSlice";
import settingsReducer from "./settingsSlice";
import conversationsReducer from "./conversationsSlice";

export default {
  nightMode: nightModeReducer,
  theme: themeReducer,
  user: userReducer,
  auth: authReducer,
  restaurants: restaurantsReducer,
  orders: ordersReducer,
  dashboard: dashboardReducer,
  ingredients: ingredientsReducer,
  packages: packagesReducer,
  settings: settingsReducer,
  conversations: conversationsReducer,
};
