import { useEffect, useRef } from 'react';
import { BaseFormInstance } from '../BaseForm/BaseForm';

export const useResetFormOnCloseModal = ({ form, open }: { form: BaseFormInstance; open: boolean }): void => {
  const prevOpenRef = useRef<boolean>();
  useEffect(() => {
    prevOpenRef.current = open;
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);
  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!open && prevOpen) {
      form.resetFields();
    }
  }, [open, form, prevOpen]);
};
