import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../../common/PageTitle/PageTitle";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { BaseImage } from "../../../common/BaseImage/BaseImage";
import download from "../../../../assets/images/download.png";
import { Excel } from "antd-table-saveas-excel";
import { DatePicker } from "antd";
import moment from "moment";
import { BASE_COLORS } from "../../../../styles/constants";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { LeftCircleFilled } from "@ant-design/icons";
import { MdDownloadForOffline } from "react-icons/md";

const TopAccounts: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/reporting");
  };
  const handleDownloadClick = () => {};

  return (
    <>
      <PageTitle>{"Produits populaires"}</PageTitle>
      <BaseRow
        justify={"space-between"}
        style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "1rem" }}
      >
        <BaseRow>
          <LeftCircleFilled
            onClick={handleClick}
            style={{
              marginRight: "1rem",
              cursor: "pointer",
              color: "#5daf4b",
              fontSize: "40px",
            }}
          />
          <DatePicker
            style={{ height: "3rem" }}
            // value={selectedDate ? moment(selectedDate) : null}
            // onChange={(date: any) => {
            //   if (date) {
            //     dispatch(setDay(moment(date).format("YYYY/MM/DD")));
            //     console.log(date);
            //   }
            // }}
          />
        </BaseRow>
        <MdDownloadForOffline
          onClick={handleDownloadClick}
          style={{
            marginRight: "1rem",
            cursor: "pointer",
            color: "#5daf4b",
            fontSize: "50px",
          }}
        />
      </BaseRow>
    </>
  );
};

export default TopAccounts;
