import { Link } from "react-router-dom";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import * as S from "./TopProducts.styles";
import { ProductItem } from "../ProductItem/ProductItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface TopProductsProps {
  title: string;
}

export const TopProducts: React.FC<TopProductsProps> = ({ title }) => {
  const { produitPopulaire } = useSelector(
    (state: RootState) => state.dashboard
  );

  return (
    <>
      <S.TopProductsCard>
        <S.CardHeader>
          <BaseTypography>{title}</BaseTypography>
          <Link
            to={""}
            style={{
              textDecoration: "underline",
              color: "var(--text-main-color)",
            }}
          >
            Voir Tout
          </Link>
        </S.CardHeader>
        {produitPopulaire.length > 0 ? (
          produitPopulaire.map((p, index) => (
            <ProductItem
              key={index}
              position={index + 1}
              ingredientName={p.name}
              deliveryTotale={p.commande}
            ></ProductItem>
          ))
        ) : (
          <p style={{ textAlign: "center", marginTop: "5rem" }}>
            Aucun Produit vendu pour le moment <ExclamationCircleOutlined />
          </p>
        )}
      </S.TopProductsCard>
    </>
  );
};
