import React, { useEffect, useState } from "react";
import * as S from "../Header.styles";
import { BaseCol } from "../../common/BaseCol/BaseCol";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import moment from "moment";
import "moment/locale/fr";
import { BaseTypography } from "../../common/BaseTypography/BaseTypography";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchInput } from "../../common/inputs/SearchInput/SearchInput.styles";
import { Input, Modal, Switch } from "antd";
import { BellOutlined, SearchOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setTheme } from "../../../store/slices/themeSlice";
import { LuSunMedium } from "react-icons/lu";
import { MdOutlineDarkMode } from "react-icons/md";
import * as S1 from "./DesktopHeader.styles";
import { NotificationsModal } from "../components/Modal/NotificationsModal";
import { RootState } from "../../../store/store";

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isTwoColumnsLayout,
}) => {
  let currentDate = moment().locale("fr").format("MMMM Do YYYY, hh:mm A");
  const location = useLocation();

  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.theme);

  const onChange = (checked: boolean) => {
    const newTheme = checked ? "dark" : "light";
    dispatch(setTheme(newTheme));
  };

  const { conversations } = useAppSelector(
    (state: RootState) => state.conversations
  );

  const totalUnreadCount = conversations.reduce(
    (acc, conv) => acc + conv.unreadCount,
    0
  );

  console.log(totalUnreadCount);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={6} xxl={6}>
      <BaseRow justify="space-between" style={{ paddingLeft: "15px" }}>
        <S.SearchInput
          size="small"
          placeholder="Recherche"
          prefix={<SearchOutlined />}
        />
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <S.SearchColumn xl={6} xxl={6}>
        <BaseRow justify="space-between" style={{ marginLeft: "-19px" }}>
          <S.SearchInput
            size="small"
            placeholder="Recherche"
            prefix={<SearchOutlined />}
          />
        </BaseRow>
      </S.SearchColumn>
    </>
  );

  return (
    <S1.StyledRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={5}>
          <BaseCol style={{ display: "flex", alignItems: "center" }}>
            <LuSunMedium
              style={{
                width: "1.5rem",
                height: "auto",
                marginRight: "5px",
              }}
            />
            <Switch
              defaultChecked={theme === "dark" ? true : false}
              onChange={onChange}
            ></Switch>
            <MdOutlineDarkMode
              style={{
                width: "1.5rem",
                height: "auto",
                marginLeft: "5px",
              }}
            />
          </BaseCol>
          <BaseCol>
            <S.NotificationWrapper onClick={() => navigate("/conversations")}>
              <S.MessageIcon></S.MessageIcon>
              {totalUnreadCount > 0 && <S.NotificationRed></S.NotificationRed>}
            </S.NotificationWrapper>
          </BaseCol>
          <BaseCol>
            <S.NotificationWrapper onClick={showModal}>
              <S.NotificationIcon />
              <S.NotificationRed></S.NotificationRed>
            </S.NotificationWrapper>
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
      <NotificationsModal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </S1.StyledRow>
  );
};
