import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ReportingPage.styles";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { useResponsive } from "../../hooks/useResponsive";
import { Reporting } from "../../components/reporting/Reporting";
import GeneralReporting from "../../components/reporting/GeneralReporting/GeneralReporting";

const ReportingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("common.menu.reporting")}</PageTitle>
      <Reporting />
    </>
  );
};

export default ReportingPage;
