import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BaseModal } from "../../common/BaseModal/BaseModal";
import { BaseForm } from "../../common/forms/BaseForm/BaseForm";
import { BaseInput } from "../../common/inputs/BaseInput/BaseInput";
import { BaseSelect } from "../../common/selects/BaseSelect/BaseSelect";
import { useResetFormOnCloseModal } from "../../common/forms/ControlForm/useResetFormOnCloseModal";
import { categoryOptions } from "../../../constants/config/category";
import { addIngredient } from "../../../store/slices/ingredientsSlice";
import { statutOptions } from "../../../constants/config/statut";
import { Button, Upload, UploadProps, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { unitOptions } from "../../../constants/config/units";
import { BaseRow } from "../../common/BaseRow/BaseRow";
import { BaseCol } from "../../common/BaseCol/BaseCol";

interface AddModalProps {
  open: boolean;
  onCancel: () => void;
  onAddSuccess: any;
}

export const AddModal: React.FC<AddModalProps> = ({
  open,
  onCancel,
  onAddSuccess,
}) => {
  const dispatch = useDispatch();
  const [form] = BaseForm.useForm();

  const props: UploadProps = {
    name: "image",
    beforeUpload: () => {
      return false;
    },
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    accept: ".jpeg,.jpg,.png",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = async () => {
    try {
      const values: any = await form.validateFields();
      console.log(values);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      formData.append("image", values.image.file);

      dispatch(addIngredient(formData))
        .then(() => {
          form.resetFields();
          onCancel();
          onAddSuccess();
        })
        .catch((error: any) => {
          console.error("Error adding ingredient:", error);
        });
    } catch (error) {
      console.error("Error validating fields:", error);
    }
  };

  console.log(open);

  const { t } = useTranslation();

  return (
    <BaseModal
      cancelText="Annuler"
      title={"Ajouter un nouveau ingrédient"}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      style={{ top: 20 }}
    >
      <BaseForm
        form={form}
        layout="vertical"
        name="ingredientForm"
        encType="multipart/form-data"
      >
        <BaseRow gutter={16}>
          <BaseCol span={12}>
            <BaseForm.Item
              name="image"
              label={t("inventory.ingredientsColumns.image")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <Upload {...props} maxCount={1}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </BaseForm.Item>
          </BaseCol>
          <BaseCol span={12}>
            <BaseForm.Item
              name="name"
              label={t("inventory.ingredientsColumns.name")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={16}>
          <BaseCol span={12}>
            <BaseForm.Item
              name="quantity"
              label={t("inventory.ingredientsColumns.stock")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput type="number" />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol span={12}>
            <BaseForm.Item
              name="prix"
              label={t("inventory.ingredientsColumns.price")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={16}>
          <BaseCol span={12}>
            <BaseForm.Item
              name="unity"
              label={t("inventory.ingredientsColumns.unit")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseSelect options={unitOptions} />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol span={12}>
            <BaseForm.Item
              name="statu"
              label={t("inventory.ingredientsColumns.statu")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseSelect options={statutOptions} />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={16}>
          <BaseCol span={12}>
            <BaseForm.Item
              name="moq"
              label={t("inventory.ingredientsColumns.moq")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput type="number" />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol span={12}>
            <BaseForm.Item
              name="limit"
              label={t("inventory.ingredientsColumns.limit")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput type="number" />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={16}>
          <BaseCol span={12}>
            <BaseForm.Item
              name="fournisseur_categories_id"
              label={t("inventory.ingredientsColumns.category")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseSelect options={categoryOptions} />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol span={12}>
            {" "}
            <BaseForm.Item
              name="note"
              label={t("inventory.ingredientsColumns.notes")}
              rules={[{ required: true, message: t("common.requiredField") }]}
            >
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseForm>
    </BaseModal>
  );
};
