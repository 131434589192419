import { useSelector } from "react-redux";
import * as S from "./Card.styles";
import { RootState } from "../../../store/store";

interface CardProps {
  title: string;
  numberResult: number;
  color: string;
  textColor: string;
}

export const Card: React.FC<CardProps> = ({
  title,
  numberResult,
  color,
  textColor,
}) => {
  return (
    <>
      <S.SummaryCard style={{ backgroundColor: color }}>
        <S.CardBody>
          <S.CardTitle style={{ color: textColor }}>{title}</S.CardTitle>
          <S.CardNumber style={{ color: textColor }}>
            {numberResult}
          </S.CardNumber>
        </S.CardBody>
      </S.SummaryCard>
    </>
  );
};
