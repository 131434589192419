import { useSelector } from "react-redux";
import * as S from "./Card.styles";
import { RootState } from "../../../store/store";

interface CardProps {
  title: string;
  numberResult: number | null | undefined;
  icon: string;
  unit: string;
}

export const Card: React.FC<CardProps> = ({
  title,
  numberResult,
  icon,
  unit,
}) => {
  return (
    <>
      <S.SummaryCard>
        <S.ImageWrapper>
          <S.CardImage
            src={icon}
            preview={false}
            alt="Card Image"
          ></S.CardImage>
        </S.ImageWrapper>
        <S.CardBody>
          <S.CardTitle>{title}</S.CardTitle>
          <S.CardNumber>{numberResult + "  " + unit}</S.CardNumber>
        </S.CardBody>
      </S.SummaryCard>
    </>
  );
};
