import styled from "styled-components";
import { BaseTypography } from "../../../common/BaseTypography/BaseTypography";
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
} from "../../../../styles/constants";
import { Card } from "antd";
import { BaseButton } from "../../../common/BaseButton/BaseButton";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { DeleteFilled } from "@ant-design/icons";

export const CardWrapper = styled(Card)`
  height: 16.5rem;
  width: 15rem;
  background-color: var(--card-background-color);
  border: none;
  font-family: ${FONT_FAMILY.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-card-actions {
    background-color: transparent;
    border: none;
    margin-top: -2rem;
  }
  & .ant-card-head {
    border: none;
    padding-bottom: 0;
  }
  .ant-card-head-title {
    padding-bottom: 0;
  }
`;

export const Name = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xl};
  color: #01c592;
  margin-bottom: 1rem;
`;

export const Info = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xs};
`;

export const InfoSpan = styled.span`
  font-weight: ${FONT_WEIGHT.bold};
  margin-left: 5px;
`;

export const Divider = styled.hr`
  width: 100%;
  color: var(--text-sider-secondary-color);
  opacity: 0.2;
  margin-top: 1rem;
  margin-bottom: 5px;
`;

export const SmallInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const SmallInfoText = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxxs};
  color: var(--text-sider-secondary-color);
`;

export const SmallInfoDate = styled(BaseTypography)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.bold};
`;

export const ActionWrapper = styled(BaseRow)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const DeleteBtn = styled(BaseButton)`
  border: 1px solid var(--text-main-color);
  background-color: transparent;

  &:focus,
  &:hover {
    border: 1px solid #ff5252;
    color: #ff5252;
  }
`;

export const AcceptBtn = styled(BaseButton)`
  width: 9rem;
  background-color: var(--heading-color);
  color: var(--layout-body-bg-color);
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const BottomInfo = styled.div`
  margin-top: auto;
`;

export const DeleteIcon = styled(DeleteFilled)`
  margin-right: 0.5rem;
  color: var(--heading-color);
  font-size: 1.5rem;
`;
