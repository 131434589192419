import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  RestaurantData,
  RestaurantPackageData,
  acceptDemandeApi,
  deleteConnectedStoreApi,
  fetchAllInvitesApi,
  fetchAllRestaurantsApi,
  fetchConnectedRestaurantsApi,
  rejectDemandeApi,
} from "../../api/restaurants.api";
import {
  ConnectedRestaurant,
  InviteList,
  Restaurant,
} from "../../domain/restaurantModel";

interface RestaurantsState {
  allRestaurants: { magasins: [] };
  invites: { demandes: [] };
  connectedRestaurants: { magasins: [] };
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: RestaurantsState = {
  allRestaurants: { magasins: [] },
  invites: { demandes: [] },
  connectedRestaurants: { magasins: [] },
  loading: "idle",
};

export const getAllRestaurants: any = createAsyncThunk(
  "restaurants/getAllRestaurants",
  async () => {
    const restaurants: Restaurant[] = await fetchAllRestaurantsApi(
      "/magasin_ville"
    );
    return restaurants;
  }
);

export const getConnectedRestaurants: any = createAsyncThunk(
  "restaurants/getConnectedRestaurants",
  async () => {
    const connectedRestaurants: ConnectedRestaurant[] =
      await fetchConnectedRestaurantsApi("/magasinConnecte");
    return connectedRestaurants;
  }
);

export const getInvites: any = createAsyncThunk(
  "restaurants/getInvites",
  async () => {
    const invites: InviteList = await fetchAllInvitesApi("/magasin_demande");
    return invites;
  }
);

export const acceptInvite: any = createAsyncThunk(
  "restaurants/acceptInvite",
  async (restauData: RestaurantPackageData, { dispatch }) => {
    const response = await acceptDemandeApi(restauData, "/acceptDemande");
    dispatch(getInvites());
    dispatch(getConnectedRestaurants());
    return response;
  }
);

export const rejectInvite: any = createAsyncThunk(
  "restaurants/rejectInvite",
  async (restauData: RestaurantData, { dispatch }) => {
    const response = await rejectDemandeApi(restauData, "/rejectDemande");
    dispatch(getInvites());
    return response;
  }
);

export const deleteConnectedStore: any = createAsyncThunk(
  "restaurants/deleteConnected",
  async (restauData: RestaurantData, { dispatch }) => {
    const response = await deleteConnectedStoreApi(
      restauData,
      "/deleteDemande"
    );
    dispatch(getConnectedRestaurants());
    return response;
  }
);

const restaurantsSlice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRestaurants.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllRestaurants.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.allRestaurants = action.payload;
      })
      .addCase(getAllRestaurants.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getInvites.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInvites.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.invites = action.payload;
      })
      .addCase(getInvites.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getConnectedRestaurants.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getConnectedRestaurants.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.connectedRestaurants = action.payload;
      })
      .addCase(getConnectedRestaurants.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default restaurantsSlice.reducer;
