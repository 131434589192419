import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../common/PageTitle/PageTitle";
import { useNavigate } from "react-router-dom";
import { BaseImage } from "../../../common/BaseImage/BaseImage";
import download from "../../../../assets/images/download.png";
import { DatePicker } from "antd";
import { BASE_COLORS } from "../../../../styles/constants";
import { BaseRow } from "../../../common/BaseRow/BaseRow";
import { LeftCircleFilled } from "@ant-design/icons";
import * as S from "./PrePaidOrders.styles";
import { BasicTable } from "./BasicTable/BasicTable";
import { MdDownloadForOffline } from "react-icons/md";
const columns: any = [
  {
    title: "N° de la commande",
    dataIndex: "orderNumber",
    width: "15%",
    editable: true,
  },
  {
    title: "Restaurant",
    dataIndex: "restaurant",
    width: "25%",
    editable: true,
  },
  {
    title: "Somme",
    dataIndex: "somme",
    width: "20%",
    editable: true,
  },
];

const PrePaidOrders: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/reporting");
  };
  const handleDownloadClick = () => {};

  useEffect(() => {
    // dispatch(getReportByDay(selectedDate));
    setLoading(false);
  }, []);

  return (
    <>
      <PageTitle>{"Produits populaires"}</PageTitle>
      <BaseRow
        justify={"space-between"}
        style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "1rem" }}
      >
        <BaseRow>
          <LeftCircleFilled
            onClick={handleClick}
            style={{
              marginRight: "1rem",
              cursor: "pointer",
              color: "#5daf4b",
              fontSize: "40px",
            }}
          />
          <DatePicker
            style={{ height: "3rem" }}
            // value={selectedDate ? moment(selectedDate) : null}
            // onChange={(date: any) => {
            //   if (date) {
            //     dispatch(setDay(moment(date).format("YYYY/MM/DD")));
            //     console.log(date);
            //   }
            // }}
          />
        </BaseRow>
        <MdDownloadForOffline
          onClick={handleDownloadClick}
          style={{
            marginRight: "1rem",
            cursor: "pointer",
            color: "#5daf4b",
            fontSize: "50px",
          }}
        />
      </BaseRow>
      <S.TablesWrapper>
        <BasicTable loading={loading} columns={columns} />
      </S.TablesWrapper>
    </>
  );
};

export default PrePaidOrders;
