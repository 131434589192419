import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Ingredient,
  fetchIngredientsApi,
  addIngredientsApi,
  updateIngredientApi,
  deleteIngredientApi,
} from "../../api/ingredients.api";
import {
  Package,
  addPackageApi,
  deletePackageApi,
  fetchPackagesApi,
  updatePackageApi,
} from "../../api/packages.api";

interface PackagesState {
  packages: Package[];

  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: PackagesState = {
  packages: [],
  loading: "idle",
};

const API_URL = "/packages";

export const fetchPackages: any = createAsyncThunk(
  "Packages/fetchPackages",
  async () => {
    const packages: Package[] = await fetchPackagesApi(API_URL);
    return packages;
  }
);

export const addPackage: any = createAsyncThunk(
  "Packages/addPackage",
  async (newPackage: Package, { dispatch }) => {
    const packaage = await addPackageApi(newPackage, API_URL);
    await dispatch(fetchPackages());
    return packaage;
  }
);

export const deletePackage: any = createAsyncThunk(
  "Packages/deletePackage",
  async (packageId: number, { dispatch }) => {
    const packaage = deletePackageApi(packageId, API_URL);
    dispatch(fetchPackages());
    return packaage;
  }
);

export const updatePackage: any = createAsyncThunk(
  "Packages/updatePackage",
  async (updatedPackage: Package, { dispatch }) => {
    const Package = updatePackageApi(updatedPackage, API_URL);
    await dispatch(fetchPackages());
    return Package;
  }
);

const packagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        const { packages } = action.payload;
        state.loading = "succeeded";
        state.packages = packages;
      })
      .addCase(fetchPackages.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export default packagesSlice.reducer;
