import React from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common/PageTitle/PageTitle";
import { MyRestaurants } from "../../components/restaurants/MyRestaurants/MyRestaurants";

const MyRestaurantsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t("common.menu.restaurants")}</PageTitle>
      <MyRestaurants />
    </>
  );
};

export default MyRestaurantsPage;
