import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  Package,
  addPackageApi,
  deletePackageApi,
  fetchPackagesApi,
  updatePackageApi,
} from "../../api/packages.api";
import {
  Conversation,
  Message,
  MessageData,
  Messages,
  fetchConversationsApi,
  fetchSelectedConversationsApi,
  sendMessageApi,
} from "../../api/conversations.api";

interface ConversationState {
  selectedConversation: number;
  conversations: Conversation[];
  messages: Messages;
  loading: "idle" | "pending" | "succeeded" | "failed";
}

const initialState: ConversationState = {
  selectedConversation: 0,
  conversations: [],
  messages: {
    magasin_id: 0,
    messages: {
      current_page: 0,
      data: [],
      first_page_url: "",
      from: 0,
      next_page_url: null,
      path: "",
      per_page: 0,
      prev_page_url: null,
      to: 0,
    },
  },
  loading: "idle",
};

export const fetchConversations: any = createAsyncThunk(
  "Conversations/fetchConversations",
  async () => {
    const conversations: Conversation[] = await fetchConversationsApi(
      "/fournisseurConversation"
    );
    return conversations;
  }
);

export const fetchSelectedConversation: any = createAsyncThunk(
  "Conversations/fetchSelectedConversation",
  async (conversationId: number) => {
    const conversations: Messages = await fetchSelectedConversationsApi(
      conversationId,
      "/fournisseurConversationMessage"
    );
    return conversations;
  }
);

export const setSelectedConversation = createAction<number>(
  "conversations/setSelectedConversation"
);

export const sendMessage: any = createAsyncThunk(
  "Conversations/sendMessage",
  async (
    payload: { message: MessageData; conversationId: number },
    { dispatch }
  ) => {
    const response = await sendMessageApi(payload.message, "/sendMessage");
    return response;
  }
);

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    resetMessages: (state) => {
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        const { conversations } = action.payload;
        state.loading = "succeeded";
        state.conversations = conversations;
      })
      .addCase(fetchConversations.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchSelectedConversation.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchSelectedConversation.fulfilled, (state, action) => {
        const messages = action.payload;
        state.loading = "succeeded";
        console.log(messages);
        state.messages = messages;
      })
      .addCase(setSelectedConversation, (state, action) => {
        state.selectedConversation = action.payload;
      })
      .addCase(fetchSelectedConversation.rejected, (state) => {
        state.loading = "failed";
      });
  },
});
export const { resetMessages } = conversationsSlice.actions;
export default conversationsSlice.reducer;
