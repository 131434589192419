import { BaseOptionType } from "antd/lib/select";

export const unitOptions: BaseOptionType[] = [
  { value: "grams", label: "Grams" },
  { value: "kilograms", label: "Kilograms" },
  { value: "liters", label: "Liters" },
  { value: "milliliters", label: "Milliliters" },
  { value: "teaspoons", label: "Teaspoons" },
  { value: "tablespoons", label: "Tablespoons" },
  { value: "cups", label: "Cups" },
  { value: "pieces", label: "Pieces" },
];
